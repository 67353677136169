import React from 'react'

export const ArrowRight = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.42499 16.6L12.8583 11.1667C13.5 10.525 13.5 9.47499 12.8583 8.83333L7.42499 3.39999"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowRight.displayName = 'ArrowRight'
