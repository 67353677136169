import React from 'react'

export const TailedArrowRight = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M14.43 5.93 20.5 12l-6.07 6.07M3.5 12h16.83"
    />
  </svg>
))

TailedArrowRight.displayName = 'TailedArrowRight'
