import styled from 'styled-components'

import { Heading as BaseHeading } from '../../../../../components/ui/Typography'

export const Container = styled.div`
  margin: 0 auto;
  text-align: center;
`

export const Heading = styled(BaseHeading)`
  letter-spacing: 0.01em;
  margin-bottom: 23px;
  text-align: right;

  @media (max-width: 1279px) {
    text-align: left;
  }
`

export const ShopLogoWrapper = styled.a`
  display: inline-flex;
  height: 40px;
  color: currentColor;
  border-radius: 6px;
  overflow: hidden;
  text-decoration: none;

  & > svg {
    width: 100%;
  }
`

export const ShopLogoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;

  @media (max-width: 1279px) {
    justify-content: flex-start;
  }
`
