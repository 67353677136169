import styled from 'styled-components'

import { Heading as BaseHeading } from '../../../../../components/ui/Typography'
import { Button as BaseButton } from '../../../../../components/ui/Button'

export const Container = styled.a`
  position: relative;
  height: 108px;
  padding: 18px 20px;
  border-radius: 20px;
  display: block;
  background: #eff3f7;
  text-decoration: none;
  color: currentColor;
  cursor: pointer;
`

export const Button = styled(BaseButton)`
  color: #fff;
  background: #ff312c;
  height: 36px;
  border-radius: 30px;
  padding: 0 16px;
`

export const Heading = styled(BaseHeading)`
  margin-bottom: 12px;
`

export const KeyContainer = styled.div`
  bottom: -5px;
  right: 8px;
  position: absolute;
  width: 140px;

  & > svg {
    width: 100%;
  }
`
