import * as React from 'react'
import { useBoolean, useStateCallback, usePositioner, useKeyPress } from '@r1team/react-hooks'
import { isDefined, noop, safeInvoke, getNextIndex, getPrevIndex } from '@r1team/react-utils'

import { theme } from '../../../../styles/theme'

import { Portal } from '../../Portal'
import { Input } from '../../Input'
import { Text } from '../../Typography'

import { AutocompleteProps, AutocompleteValue, AutocompleteOption } from '../types/Autocomplete.types'
import { Container, Menu } from '../styles/Autocomplete.sc'

export const defaultNotFoundText = 'К сожалению, по вашим параметрам ничего не найдено, попробуйте изменить их'

/**
 * @description The Autocomplete component allow user to type and select from a list of options
 *
 * @component
 * @example
 * ```jsx
 * <Autocomplete placeholder="Услуги" defaultValue={value} options={options} />
 * ```
 */
const Autocomplete = React.forwardRef<HTMLInputElement, AutocompleteProps>((props, ref) => {
  const {
    defaultValue,
    value,
    options,
    className,
    icon,
    readOnly,
    placement,
    fixedWidth,
    notFoundContent,
    onChange,
    onSelect,
    onSearch,
    onFocus,
    onBlur,
    ...restProps
  } = props

  const inputRef = React.useRef<HTMLInputElement>(null)
  const inputContainerRef = React.useRef<HTMLDivElement>(null)
  const reference = React.useRef<Element | null>(null)
  const popper = React.useRef<HTMLElement | null>(null)
  const focusedItemRef = React.useRef<HTMLDivElement | null>(null)
  const [visible, setVisible] = useBoolean(false)
  const [fixWidth, setFixWidth] = useBoolean(fixedWidth)
  const [innerValue, setInnerValue] = useStateCallback<AutocompleteValue>(isDefined(value) ? value : defaultValue)
  const [inputValue, setInputValue] = React.useState<string | undefined>('')
  const [innerOptions, setInnerOptions] = React.useState<AutocompleteOption[]>(options)
  const [focusedIndex, setFocusedIndex] = React.useState(-1)
  const { referenceRef, popperRef, getReferenceProps, getPopperProps } = usePositioner({
    placement: placement ? placement : 'bottom-start',
    matchWidth: true,
    offset: [0, 10],
  })

  const optionsMap = React.useMemo<Record<AutocompleteOption['value'], AutocompleteOption>>(() => {
    if (!options) {
      return {}
    }

    return options.reduce<Record<AutocompleteOption['value'], AutocompleteOption>>((acc, o) => {
      acc[o.value] = o

      return acc
    }, {})
  }, [options])

  const activeOptions = React.useMemo<AutocompleteOption[]>(() => {
    if (!innerOptions) {
      return []
    }

    return innerOptions.filter((option) => !option.disabled)
  }, [innerOptions])

  const getCurrentIndex = (value: any) => {
    if (!Array.isArray(options) || !value) return -1

    return activeOptions.findIndex((option) => option.value === value)
  }

  const getOptionByValue = React.useCallback(
    (value: string | number) => {
      return optionsMap[value] || {}
    },
    [optionsMap],
  )

  const displayValue = React.useMemo(() => {
    let output = inputValue

    if (!visible) {
      const { label } = getOptionByValue(innerValue as string)

      output = label
    }

    return output || ''
  }, [visible, inputValue, innerValue, optionsMap])

  const isActive = React.useCallback(
    (val: string | number) => {
      if (innerValue === undefined) {
        return false
      }

      return innerValue === val
    },
    [innerValue],
  )

  const getFocusedValue = React.useCallback(() => {
    const item = activeOptions[focusedIndex]

    if (item) {
      return item.value
    }

    return null
  }, [focusedIndex])

  const setValue = (value: string | number | undefined): void => {
    setInnerValue(value, (val: any) => {
      let option
      if (val !== undefined) {
        option = getOptionByValue(val as string)
      }

      setInputValue(option ? option.label : (val as string))
      safeInvoke(onChange, val)
      safeInvoke(onSelect, option)
    })
  }

  const blurInput = () => {
    inputRef.current?.blur()
    setVisible.off()
  }

  const handleSelect = (value: string | number) => () => {
    setValue(value)
    window.requestAnimationFrame(blurInput)
  }

  const handleChangeInput = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value

    setFocusedIndex(getCurrentIndex(innerValue))
    setInputValue(value)
    setVisible.on()

    if (onSearch === undefined) {
      setInnerOptions(
        options.filter((o) => {
          return o.label.toLowerCase().startsWith(value.toLowerCase())
        }),
      )
    }

    safeInvoke(onSearch, value)
  }

  const handleMouseMove = () => {
    setFocusedIndex(getCurrentIndex(innerValue))
  }

  const menu = React.useMemo(() => {
    if (!innerOptions || innerOptions.length === 0) {
      setFixWidth.on()

      return (
        <Menu>
          {notFoundContent ? (
            notFoundContent
          ) : (
            <Menu.Item disabled>
              <Text className="h-text-center h-color-D60">{defaultNotFoundText}</Text>
            </Menu.Item>
          )}
        </Menu>
      )
    }

    return (
      <Menu>
        {innerOptions.map(({ label, value, disabled, ...args }) => (
          <Menu.Item
            key={value}
            ref={getFocusedValue() === value ? focusedItemRef : null}
            {...args}
            active={isActive(value) || getFocusedValue() === value}
            disabled={disabled}
            onClick={disabled ? noop : handleSelect(value)}
            onMouseOver={handleMouseMove}
          >
            {label}
          </Menu.Item>
        ))}
      </Menu>
    )
  }, [innerOptions, innerValue, focusedIndex])

  const handleFocusInput = React.useCallback(
    (event: React.MouseEvent | React.FocusEvent) => {
      if (!readOnly && !visible) {
        setVisible.on()
        safeInvoke(onFocus, event)
      }
    },
    [readOnly, visible],
  )

  const handleBlurInput = React.useCallback(
    (event: Event) => {
      setInnerValue((innerValue: any) => {
        const { label } = getOptionByValue(innerValue as string)

        setInputValue(label)

        return innerValue
      })

      setInnerOptions(options)
      safeInvoke(onBlur, event)
      blurInput()
    },
    [getOptionByValue, onBlur],
  )

  const handleClickClear = React.useCallback(() => {
    setFocusedIndex(-1)
    setValue(undefined)
    setInnerOptions(options)
  }, [])

  const handleArrowClick = (event: React.MouseEvent) => {
    if (visible) {
      event.stopPropagation()
      safeInvoke(onBlur, event)
      blurInput()
      setInnerOptions(options)
      setInputValue(undefined)
    }
  }

  const iconElement = React.useMemo(() => {
    if (icon) {
      return icon
    }

    // if (visible && innerValue !== undefined) {
    //   return <Cross className={cx('icon')} onClick={handleClickClear} />
    // }
    //
    // return (
    //   <ArrowDown
    //     className={cx('icon', {
    //       [`isArrowTurned`]: visible,
    //     })}
    //     onClick={handleArrowClick}
    //   />
    // )
  }, [visible, innerValue, icon])

  useKeyPress(
    {
      enabled: visible,
      keyMap: {
        ArrowDown: (event: any) => {
          event.preventDefault()
          const nextIndex = getNextIndex(focusedIndex, activeOptions.length)
          setFocusedIndex(nextIndex)
        },
        ArrowUp: (event: any) => {
          event.preventDefault()
          const prevIndex = getPrevIndex(focusedIndex, activeOptions.length)
          setFocusedIndex(prevIndex)
        },
        Enter: (event: any) => {
          event.preventDefault()
          const value = getFocusedValue()

          if (value !== undefined && value !== null) {
            setValue(value)
            blurInput()
          }
        },
        Escape: (event: any) => {
          event.preventDefault()
          safeInvoke(onBlur, event)
          blurInput()
        },
      },
    },
    [focusedIndex, activeOptions],
  )

  React.useEffect(() => {
    if (defaultValue || value) {
      const { label } = getOptionByValue((defaultValue as string) || (value as string))

      setInputValue(label)
    }
  }, [])

  React.useEffect(() => {
    setInnerOptions(options)
  }, [options])

  React.useEffect(() => {
    visible && setFocusedIndex(getCurrentIndex(innerValue))
  }, [visible])

  React.useEffect(() => {
    focusedItemRef.current &&
      focusedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
  }, [focusedIndex, visible])

  React.useEffect(() => {
    const listener = (event: any) => {
      if (!visible) {
        return
      }

      // @ts-ignore
      if (reference.current && reference.current.contains(event.target)) {
        return
      }

      // @ts-ignore
      if (inputContainerRef.current && inputContainerRef.current.contains(event.target)) {
        return
      }

      // @ts-ignore
      if (popper.current && popper.current.contains(event.target)) {
        return
      }

      handleBlurInput(event)
    }

    document.addEventListener('mousedown', listener)
    // document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      // document.removeEventListener('touchstart', listener)
    }
  }, [reference, popper, visible, handleBlurInput])

  const view = React.useMemo(() => {
    return (
      <Portal>
        {visible && menu ? (
          <div ref={popperRef} {...getPopperProps({ style: { zIndex: theme.zindex.dropdown } }, popper)}>
            {menu}
          </div>
        ) : null}
      </Portal>
    )
  }, [visible, menu])

  React.useEffect(() => {
    // Clear all artifacts if values not exist
    if (value !== innerValue) {
      if (value === '' || value === undefined) {
        setInputValue('')
        setInnerValue('')
      } else {
        const option = getOptionByValue(value)

        if (!visible && option.label) {
          setInputValue(option.label)
          setInnerValue(value)
        }
      }
    }
  }, [value, visible, getOptionByValue])

  return (
    <Container ref={ref} data-qa="Autocomplete" className={className}>
      <div ref={referenceRef} {...getReferenceProps({}, reference)}>
        <Input
          {...restProps}
          ref={inputRef}
          readOnly={readOnly}
          value={displayValue}
          icon={iconElement}
          onClick={handleFocusInput}
          onFocus={handleFocusInput}
          onChange={handleChangeInput}
        />
      </div>
      {view}
    </Container>
  )
})

Autocomplete.displayName = 'Autocomplete'
Autocomplete.defaultProps = {
  fixedWidth: false,
  placement: 'bottom-start',
}

export default Autocomplete
