import * as React from 'react'

import { noop } from '@r1team/react-utils'

import { Check } from '../../../icons'
import { Icon } from '../../../ui/Icon'

import { CheckboxProps } from '../types/Checkbox.types'
import { Container, Input, Control, Label } from '../styles/Checkbox.sc'

/**
 * @description The Checkbox component allows user to select multiple items from a list
 *
 * @component
 * @example
 * ```jsx
 * <Checkbox name="radio" value="default" label="Checkbox default" checked disabled />
 * ```
 */
const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { size, checked, disabled, invalid, className, children, onChange, ...restProps } = props

  return (
    <Container data-qa="Checkbox" checked={checked} disabled={disabled} invalid={invalid} className={className}>
      <Input ref={ref} type="checkbox" checked={checked} disabled={disabled} onChange={onChange} {...restProps} />
      <Control size={size} checked={checked} invalid={invalid} disabled={disabled}>
        {checked ? <Icon size={size} icon={<Check />} /> : null}
      </Control>
      {children ? <Label disabled={disabled}>{children}</Label> : null}
    </Container>
  )
})

Checkbox.displayName = 'Checkbox'
Checkbox.defaultProps = {
  size: 20,
  checked: false,
  onChange: noop,
}

export default Checkbox
