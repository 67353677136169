import React from 'react'

// #518399
export const VKLogo = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" width={18} {...props}>
    <path
      d="M9.813 11.608C3.67 11.608.166 7.396.02.388h3.077c.101 5.144 2.37 7.323 4.167 7.772V.388h2.897v4.437C11.936 4.634 13.8 2.612 14.43.388h2.897c-.483 2.74-2.504 4.762-3.942 5.593 1.438.674 3.74 2.437 4.616 5.627h-3.19c-.685-2.134-2.392-3.785-4.649-4.01v4.01h-.348Z"
      fill="currentColor"
    />
  </svg>
))
