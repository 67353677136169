import { FieldValidator } from 'final-form'

const KEY_REGEX = /^[0-9a-f]+$/gi
const ONLY_NUMBERS = /^\d+$/gi

export function requiredValidation(value: any) {
  return value
    ? undefined
    : // @ts-ignore
      this?.message || 'Обязательное поле'
}

export function keyValidation(value: string) {
  KEY_REGEX.lastIndex = 0

  if (!KEY_REGEX.test(value)) {
    // @ts-ignore
    return this?.message || 'Неккоректный номер'
  }
}

export function keyLengthValidation(value: string) {
  if (value.length !== 8) {
    // @ts-ignore
    return this?.message || 'Номер состоит из 8 символов'
  }
}

export function onlyNumbers(value: string) {
  KEY_REGEX.lastIndex = 0

  if (!KEY_REGEX.test(value)) {
    // @ts-ignore
    return this?.message || 'Только цифры'
  }
}

export const wrapValidationMessage = (validator: FieldValidator<any>, message: string): FieldValidator<any> => {
  return validator.bind({ message })
}

export const composeValidators = (validators: FieldValidator<any>[]): FieldValidator<any> => {
  return (...args) => {
    return validators.reduce<any>((error, validator) => {
      return error || validator(...args)
    }, undefined)
  }
}
