import { Container, Heading, ShopLogoContainer, ShopLogoWrapper } from './styles/Download.sc'

import AppStore from '../../../../components/images/AppStore'
import GooglePlay from '../../../../components/images/GooglePlay'
import { openAppdownloadEvent } from '../../../../events/keys'

const Download = () => (
  <Container>
    <Heading level={6}>
      Cкачайте приложение Умный дом.ру,
      <br />
      чтобы открывать дверь без ключа
    </Heading>
    <ShopLogoContainer>
      <ShopLogoWrapper target="_blank" onClick={openAppdownloadEvent} href="https://apps.apple.com/ru/app/умный-дом-ru/id1373157818">
        <AppStore />
      </ShopLogoWrapper>
      <ShopLogoWrapper target="_blank" onClick={openAppdownloadEvent} href="https://play.google.com/store/apps/details?id=com.ertelecom.smarthome&hl=ru&gl=US">
        <GooglePlay />
      </ShopLogoWrapper>
    </ShopLogoContainer>
  </Container>
)

export default Download
