import styled from 'styled-components'
import { Heading as BaseHeading, Text as BaseText } from '../../../../../components/ui/Typography'
import { Button as BaseButton } from '../../../../../components/ui/Button'

export const Container = styled.div`
  width: 321px;
  margin: 0 auto;
  text-align: center;
`

export const Heading = styled(BaseHeading)`
  margin-bottom: 24px;
`

export const Button = styled(BaseButton)`
  margin-bottom: 15px;
`

export const Text = styled(BaseText)`
  margin-bottom: 32px;
`

export const FinalStatusImageContainer = styled.a`
  display: inline-flex;
  height: 240px;
  width: 249px;
  color: currentColor;
  margin-bottom: 40px;

  & > svg {
    width: 100%;
  }
`
