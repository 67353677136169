import React from 'react'

export const Logout = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.4165 6.30001C7.67484 3.30001 9.2165 2.07501 12.5915 2.07501H12.6998C16.4248 2.07501 17.9165 3.56668 17.9165 7.29168V12.725C17.9165 16.45 16.4248 17.9417 12.6998 17.9417H12.5915C9.2415 17.9417 7.69984 16.7333 7.42484 13.7833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M12.4999 10H3.0166" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M4.87492 7.20837L2.08325 10L4.87492 12.7917" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
))

Logout.displayName = 'Logout'
