import * as React from 'react'
import { useBoolean, usePositioner, useMergedRef } from '@r1team/react-hooks'

import { theme } from '../../../../styles/theme'
import { Portal } from '../../Portal'

import { TooltipProps } from '../types/Tooltip.types'
import { Bubble, Content, Arrow } from '../styles/Tooltip.sc'

/**
 * @description The Tooltip component is used to show more content of a target
 *
 * @component
 * @example
 * ```jsx
 * <Tooltip title="prompt text">
 *   <span>Tooltip will show on mouse enter.</span>
 * </Tooltip>
 * ```
 */
const Tooltip = React.forwardRef<HTMLSpanElement, TooltipProps>((props, ref) => {
  const { title, className, children, positioner, ...restProps } = props

  const reference = React.useRef<Element | null>(null)
  const popper = React.useRef<HTMLElement | null>(null)
  const [visible, setVisible] = useBoolean(false)
  const { referenceRef, popperRef, arrowRef, getReferenceProps, getPopperProps } = usePositioner({
    placement: 'top',
    offset: [0, 7],
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right', 'bottom', 'left'],
        },
      },
    ],
    ...positioner,
  })

  const child = React.Children.only(children) as React.ReactElement

  const triggerProps = {
    ref: useMergedRef(referenceRef, ref),
    'data-qa': 'Tooltip',
    tabIndex: 0,
    ...restProps,
    ...getReferenceProps({}, reference),
  }

  triggerProps.onMouseEnter = setVisible.on
  triggerProps.onMouseLeave = setVisible.off
  triggerProps.onTouchEnd = setVisible.toggle

  const triggerElement = React.cloneElement(child, triggerProps)

  return (
    <React.Fragment>
      {triggerElement}
      <Portal>
        {visible && (
          <Bubble
            ref={popperRef}
            visible={visible}
            className={className}
            unmountOnExit
            {...getPopperProps({ style: { zIndex: theme.zindex.tooltip } }, popper)}
          >
            <Arrow ref={arrowRef} />
            <Content>{title}</Content>
          </Bubble>
        )}
      </Portal>
    </React.Fragment>
  )
})

Tooltip.displayName = 'Tooltip'

export default Tooltip
