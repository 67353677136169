import * as React from 'react'

import { SpinnerProps } from '../types/Spinner.types'
import { Container, Circle, Image } from '../styles/Spinner.sc'

/**
 * @description A spinner for displaying loading state of a page or a section
 *
 * @component
 * @example
 * ```jsx
 * <Spinner />
 * ```
 */
const Spinner = React.forwardRef<HTMLSpanElement, SpinnerProps>((props, ref) => {
  const { ...restProps } = props

  return (
    <Container ref={ref} data-qa="Spinner" {...restProps}>
      <Image viewBox="0 0 16 16">
        <Circle d="M6.18827 1.23852C6.04532 0.705054 6.36191 0.150043 6.90903 0.0747252C8.34504 -0.122957 9.81463 0.072534 11.1579 0.64967C12.809 1.35901 14.1731 2.60354 15.0305 4.18273C15.888 5.76192 16.1888 7.5838 15.8844 9.35479C15.5801 11.1258 14.6884 12.7428 13.353 13.9452C12.0177 15.1476 10.3163 15.8653 8.52323 15.9829C6.73012 16.1004 4.94967 15.6108 3.46875 14.593C1.98784 13.5752 0.892713 12.0885 0.359841 10.3723C-0.0737195 8.97605 -0.114527 7.49408 0.232175 6.0866C0.36427 5.55034 0.949333 5.29351 1.46493 5.49143V5.49143C1.98054 5.68935 2.22905 6.26758 2.11941 6.80887C1.92054 7.79075 1.96966 8.81239 2.26988 9.77925C2.66953 11.0663 3.49088 12.1814 4.60156 12.9448C5.71225 13.7081 7.04759 14.0753 8.39242 13.9872C9.73725 13.899 11.0132 13.3607 12.0148 12.4589C13.0163 11.5571 13.6851 10.3443 13.9133 9.0161C14.1416 7.68785 13.916 6.32144 13.2729 5.13705C12.6298 3.95265 11.6067 3.01925 10.3685 2.48725C9.43827 2.08761 8.42736 1.93197 7.43008 2.02712C6.88029 2.07958 6.33121 1.77199 6.18827 1.23852V1.23852Z" />
      </Image>
    </Container>
  )
})

Spinner.displayName = 'Spinner'
Spinner.defaultProps = {
  size: 20,
  variant: 'primary',
}

export default Spinner
