import React from 'react'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from './styles/global'
import { theme } from './styles/theme'
import Router from './Router'

import { Header, Content, Layout } from './components/layout'
import { Notifications } from './components/ui/Notifications'
import { Footer } from './pages/home/components/footer'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        <Header />
        <Content>
          <Router />
          <Notifications />
        </Content>
      </Layout>
      <Footer />
    </ThemeProvider>
  )
}

export default App
