import React from 'react'

// #181B21
export const OKLogo = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16" width={10} {...props}>
    <path
      d="M5.045 3.037c.839 0 1.52.682 1.52 1.52 0 .839-.681 1.52-1.52 1.52-.838 0-1.52-.681-1.52-1.52 0-.838.682-1.52 1.52-1.52Zm0 5.192a3.676 3.676 0 0 0 3.673-3.671A3.677 3.677 0 0 0 5.045.885a3.677 3.677 0 0 0-3.673 3.673 3.676 3.676 0 0 0 3.673 3.671Zm1.486 2.996a6.874 6.874 0 0 0 2.133-.883A1.076 1.076 0 1 0 7.518 8.52a4.664 4.664 0 0 1-4.945 0 1.075 1.075 0 1 0-1.146 1.822c.656.412 1.377.71 2.132.883l-2.053 2.053A1.076 1.076 0 1 0 3.028 14.8l2.017-2.018L7.063 14.8a1.075 1.075 0 1 0 1.52-1.521l-2.052-2.054Z"
      fill="currentColor"
    />
  </svg>
))
