import * as React from 'react'

import { FormControlProps } from '../types/FormControl.types'
// import { styles } from '../styles/FormControl.sc'

/**
 * @description The FormControl component is a utility component to help compose form fields
 *
 * @component
 * @example
 * ```jsx
 * <FormControl>content</FormControl>
 * ```
 */
export const ControlContext: React.Context<FormControlProps> = React.createContext({})

const FormControl = React.forwardRef<HTMLDivElement, FormControlProps>((props, ref) => {
  const { invalid = false, disabled = false, className, children, ...restProps } = props

  const formControlContext = React.useMemo(() => ({ invalid, disabled }), [invalid, disabled])

  return (
    <div ref={ref} data-qa="FormControl" className={className} {...restProps}>
      <ControlContext.Provider value={formControlContext}>{children}</ControlContext.Provider>
    </div>
  )
})

FormControl.defaultProps = {}

export default FormControl
