import styled, { css } from 'styled-components'

import { getRedTint04Color } from '../../../../styles/utils'

export const Helper = styled.div<{ invalid?: boolean }>`
  width: 100%;
  overflow: hidden;

  ${({ invalid }) =>
    invalid &&
    css`
      color: ${getRedTint04Color};
    `}
`
