import * as React from 'react'

import { IconProps } from '../types/Icon.types'
import { Container } from '../styles/Icon.sc'

/**
 * @description Icon container.
 *
 * @component
 * @example
 * ```jsx
 * <Icon size="24" icon={<ArrowUpIcon />} />
 * ```
 */
const Icon = React.forwardRef<HTMLSpanElement, IconProps>((props, ref) => {
  const { icon, children, ...restProps } = props

  return (
    <Container data-qa="Icon" ref={ref} asContainer={!!children} {...restProps}>
      {children || icon}
    </Container>
  )
})

Icon.displayName = 'Icon'
Icon.defaultProps = {
  size: 24,
}

export default Icon
