import styled, { css } from 'styled-components'

import { getSecondaryShade01Color, getWhiteShade022Color, getTextAlpha02Color, getGrayTint09Color, getRedTint04Color } from '../../../../styles/utils'

import { RadioSize } from '../types/Radio.types'

export const Container = styled.label<{
  checked?: boolean
  disabled?: boolean
  invalid?: boolean
}>`
  display: inline-flex;
  vertical-align: top;
  cursor: pointer;
  user-select: none;
  gap: 12px;

  ${({ checked, invalid }) =>
    !checked &&
    !invalid &&
    css`
      &:hover {
        ${Control} {
          border-color: ${getGrayTint09Color};
        }
      }
    `}

  ${({ checked, disabled }) =>
    checked &&
    !disabled &&
    css`
      &:hover {
        ${Control} {
          background: ${getGrayTint09Color};
          border-color: ${getGrayTint09Color};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: inherit;
      cursor: not-allowed;
      pointer-events: none;
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      &:hover {
        ${Control} {
          border-color: ${getRedTint04Color};
          background-color: ${({ theme }) => theme.colors.white};
        }
      }
    `}
`

export const Input = styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0;
`

const getSizeStyles = (size?: RadioSize) => {
  switch (size) {
    case 16:
      return css`
        width: 16px;
        height: 16px;
      `
    case 20:
      return css`
        width: 20px;
        height: 20px;
      `
    case 24:
      return css`
        width: 24px;
        height: 24px;
      `
  }
}

export const Control = styled.div<{
  checked?: boolean
  disabled?: boolean
  invalid?: boolean
  size?: RadioSize
}>`
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ${({ theme }) => theme.easing.inOutQuint};
  border-width: 2px;
  border-style: solid;
  border-color: ${getSecondaryShade01Color};
  background-color: ${({ theme }) => theme.colors.white};
  will-change: background-color, border-color;
  border-radius: 50%;

  ${({ size }) => getSizeStyles(size)}

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${({ theme }) => theme.colors.gray};
      background-color: ${({ theme }) => theme.colors.gray};
    `}

  ${({ disabled, checked }) =>
    disabled &&
    !checked &&
    css`
      border-color: ${getWhiteShade022Color};
    `}

  ${({ checked, disabled }) =>
    checked &&
    disabled &&
    css`
      border-color: ${getWhiteShade022Color};
      background-color: ${getWhiteShade022Color};
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${getRedTint04Color};
      background-color: ${({ theme }) => theme.colors.white};
    `}
`

export const Label = styled.span<{
  disabled?: boolean
}>`
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${getWhiteShade022Color};
    `}
`

const getIconSizeStyles = (size?: RadioSize) => {
  switch (size) {
    case 16:
      return css`
        width: 8px;
        height: 8px;
      `
    case 20:
      return css`
        width: 10px;
        height: 10px;
      `
    case 24:
      return css`
        width: 12px;
        height: 12px;
      `
  }
}

export const Icon = styled.div<{
  disabled?: boolean
  invalid?: boolean
  size?: RadioSize
}>`
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;

  ${({ size }) => getIconSizeStyles(size)}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${getTextAlpha02Color};
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      background-color: ${getRedTint04Color};
    `}
`
