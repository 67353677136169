import type { AxiosResponse } from 'axios'
import http from '../utils/http'

const RECAPTCHA_TOKEN_HEADER = 'X-Recaptcha-Token'

export const checkKey = async (query: IKeysCheckQuery, options: IKeysRequestOptions): Promise<AxiosResponse<any>> => {
  const { token } = options.recaptcha

  return http.get<any>('/keys/check', {
    params: query,
    timeout: 45000,
    headers: {
      [RECAPTCHA_TOKEN_HEADER]: token,
    },
  })
}

export const copyKey = async (body: IKeysCopyBody, options: IKeysRequestOptions): Promise<AxiosResponse<any>> => {
  const { token } = options.recaptcha

  return http.post<IKeysCopyBody>('/keys/copy', body, {
    timeout: 45000,
    headers: {
      [RECAPTCHA_TOKEN_HEADER]: token,
    },
  })
}
