import styled, { css } from 'styled-components'

import { getSecondaryShade01Color, getWhiteShade022Color, getTextAlpha02Color, getGrayTint09Color, getRedTint04Color } from '../../../../styles/utils'

import { Icon as OriginalIcon } from '../../Icon'
import { Spinner as OriginalSpinner } from '../../Spinner'

import { InputProps } from '../types/Input.types'

export const Container = styled.div`
  position: relative;
  overflow: hidden;
`

export const Input = styled.input<InputProps & { hasIcon?: boolean }>`
  width: 100%;
  height: 48px;
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.text};
  font-size: inherit;
  line-height: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s ${({ theme }) => theme.easing.inOut};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  //border: 2px solid ${({ theme }) => theme.colors.gray};
  border: 2px solid ${getSecondaryShade01Color};
  cursor: text;
  will-change: color, border-color, background-color;
  outline: none;

  &:hover {
    border-color: ${getGrayTint09Color};
  }

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.white};
  }

  &[disabled] {
    border-color: transparent;
    background-color: ${getWhiteShade022Color};
    color: ${getTextAlpha02Color};
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
    // -webkit-text-fill-color: ${getWhiteShade022Color};
  }

  &[readonly] {
    user-select: none;
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${getRedTint04Color};

      &:hover {
        border-color: ${getRedTint04Color};
      }
    `}

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-right: 52px;
    `}
`

export const Icon = styled(OriginalIcon)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: ${getTextAlpha02Color};
`

export const Spinner = styled(OriginalSpinner)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`
