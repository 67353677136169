import { FieldRenderProps } from 'react-final-form-hooks'

import { wrapValidationMessage, composeValidators, requiredValidation, keyValidation, keyLengthValidation, onlyNumbers } from '../utils/validation'

const makeRandomName = () => `f${(~~(Math.random() * 1e10)).toString(16)}`

// Attention: make random name uses for hack autocomplete in browser
export const NAMES = {
  regionId: makeRandomName(),
  regionName: makeRandomName(),
  streetId: makeRandomName(),
  keyCode: makeRandomName(),
  houseId: makeRandomName(),
  flat: makeRandomName(),
  operatorId: makeRandomName(),
  cloneKeyCode: makeRandomName(),
}

export const PLACEHOLDERS = {
  [NAMES.keyCode]: 'Номер ключа',
  [NAMES.cloneKeyCode]: 'Номер ключа',
  [NAMES.regionId]: 'Город',
  [NAMES.streetId]: 'Улица',
  [NAMES.houseId]: 'Дом',
  [NAMES.flat]: 'Квартира',
}

export const VALIDATORS = {
  [NAMES.cloneKeyCode]: composeValidators([wrapValidationMessage(requiredValidation, 'Введите номер ключа'), keyValidation, keyLengthValidation]),
  [NAMES.keyCode]: composeValidators([wrapValidationMessage(requiredValidation, 'Введите номер ключа'), keyValidation, keyLengthValidation]),
  [NAMES.regionId]: wrapValidationMessage(requiredValidation, 'Некорректный город'),
  [NAMES.streetId]: wrapValidationMessage(requiredValidation, 'Некорректная улица'),
  [NAMES.houseId]: wrapValidationMessage(requiredValidation, 'Некорректный дом'),
  [NAMES.flat]: composeValidators([wrapValidationMessage(requiredValidation, 'Некорректная квартира')]),
}

export const decodeValues = (values: Record<string, any>) => {
  return Object.entries(NAMES).reduce<Record<string, any>>((acc, [name, hash]) => {
    const value = values[hash]

    if (value) {
      acc[name] = value
    }

    return acc
  }, {})
}

export const encodeValues = (values: Record<string, any>) => {
  return Object.entries(NAMES).reduce<Record<string, any>>((acc, [name, hash]) => {
    const value = values[name]

    if (value) {
      acc[hash] = value
    }

    return acc
  }, {})
}

export const changeValue = (field: FieldRenderProps, value: any) => {
  field.input.onChange({
    target: {
      value,
    },
  })
}
