import * as React from 'react'

import { SpaceProps } from '../types/Space.types'
import { Container } from '../styles/Space.sc'

/**
 * @description Set components spacing
 *
 * @component
 * @example
 * ```jsx
 * <Space size="m">
 *   <span>content</span>
 *   <span>content</span>
 *   <span>content</span>
 * </Space>
 * ```
 */
const Space = React.forwardRef<HTMLDivElement, SpaceProps>((props, ref) => {
  const { children, ...restProps } = props

  return (
    <Container ref={ref} data-qa="Space" {...restProps}>
      {children}
    </Container>
  )
})

Space.displayName = 'Space'
Space.defaultProps = {
  direction: 'horizontal',
  size: 0,
  wrap: false,
}

export default Space
