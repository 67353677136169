import styled from 'styled-components'

import { Logo as LogoOriginal } from '../../ui/Logo'

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 60px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: ${({ theme }) => theme.zindex.fixed};

  @media screen and (max-width: 767px) {
    padding: 0 30px;
  }
`

export const Logo = styled(LogoOriginal)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  //width: 173px;
  height: 48px;
  overflow: hidden;
`
