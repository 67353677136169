import * as React from 'react'

import { InputProps } from '../types/Input.types'
import { Container, Input as InputStyled, Icon, Spinner } from '../styles/Input.sc'

/**
 * @description The Input component allows user to type in text
 *
 * @component
 * @example
 * ```jsx
 * <Input name="field" value="value" />
 * ```
 */
const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, icon, loading, ...restProps } = props
  const hasIcon = !!(icon || loading)

  const autocomplete = React.useMemo(() => Math.random().toString(16), [])
  const iconElement = React.useMemo(() => {
    if (loading) {
      return <Spinner size={24} />
    }

    if (icon) {
      return <Icon icon={icon} />
    }

    return null
  }, [icon, loading])

  return (
    <Container className={className} data-qa="TextInput">
      <InputStyled autoComplete={autocomplete} ref={ref} hasIcon={hasIcon} {...restProps} />
      {iconElement}
    </Container>
  )
})

Input.displayName = 'Input'
Input.defaultProps = {
  type: 'text',
}

export default Input
