import React from 'react'

export const Menu = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.5 6.4585H2.5C2.15833 6.4585 1.875 6.17516 1.875 5.8335C1.875 5.49183 2.15833 5.2085 2.5 5.2085H17.5C17.8417 5.2085 18.125 5.49183 18.125 5.8335C18.125 6.17516 17.8417 6.4585 17.5 6.4585Z"
      fill="currentColor"
    />
    <path
      d="M17.5 10.625H2.5C2.15833 10.625 1.875 10.3417 1.875 10C1.875 9.65833 2.15833 9.375 2.5 9.375H17.5C17.8417 9.375 18.125 9.65833 18.125 10C18.125 10.3417 17.8417 10.625 17.5 10.625Z"
      fill="currentColor"
    />
    <path
      d="M17.5 14.7915H2.5C2.15833 14.7915 1.875 14.5082 1.875 14.1665C1.875 13.8248 2.15833 13.5415 2.5 13.5415H17.5C17.8417 13.5415 18.125 13.8248 18.125 14.1665C18.125 14.5082 17.8417 14.7915 17.5 14.7915Z"
      fill="currentColor"
    />
  </svg>
))

Menu.displayName = 'Menu'
