import { FieldRenderProps } from 'react-final-form-hooks'
import { ReactNode } from 'react'
import { FormControl } from '../styles/Field.sc'

interface IFieldProps {
  final: FieldRenderProps
  as: ReactNode
  [key: string]: any
}

const Field = (props: IFieldProps) => {
  const { as: Component, final, className, ...rest } = props

  const innerProps = {
    ...final.input,
    ...rest,
  }

  const { error, touched } = final.meta
  const invalid = error && touched

  return (
    <FormControl invalid={invalid} className={className}>
      {/* @ts-ignore */}
      <Component invalid={invalid} {...innerProps} />
      {invalid ? <FormControl.HelperText>{error}</FormControl.HelperText> : null}
    </FormControl>
  )
}

export default Field
