const CopySuccess = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 240 249">
      <rect width="240" height="5.894" y="193.011" fill="#B5CCD1" rx="2.947" />
      <g clipPath="url(#copy-success-a)">
        <g clipPath="url(#copy-success-b)">
          <path
            fill="#B5CCD1"
            fillRule="evenodd"
            d="M136.441 62.537 39.307 232.738l85.044 48.599 97.134-170.201-85.044-48.6Zm7.802 23.539a5.322 5.322 0 0 0-1.98-7.26 5.315 5.315 0 0 0-7.254 1.983 5.323 5.323 0 0 0 1.98 7.26 5.315 5.315 0 0 0 7.254-1.983Z"
            clipRule="evenodd"
          />
        </g>
        <g clipPath="url(#copy-success-c)">
          <path
            fill="#C1D5DA"
            fillRule="evenodd"
            d="M127.604 37.487 30.469 207.688l85.044 48.6 97.134-170.201-85.043-48.6Zm7.802 23.54a5.323 5.323 0 0 0-1.98-7.26 5.316 5.316 0 0 0-7.255 1.982 5.323 5.323 0 0 0 1.98 7.26 5.316 5.316 0 0 0 7.255-1.982Z"
            clipRule="evenodd"
          />
        </g>
        <g clipPath="url(#copy-success-d)">
          {/*<mask id="copy-success-e" width="183" height="220" x="20" y="12" maskUnits="userSpaceOnUse" style="mask-type:alpha">*/}
          <mask id="copy-success-e" width="183" height="220" x="20" y="12" maskUnits="userSpaceOnUse">
            <path
              fill="#C4C4C4"
              fillRule="evenodd"
              d="M117.298 12.441 20.163 182.642l85.044 48.6 97.134-170.201-85.043-48.6Zm7.802 23.54a5.323 5.323 0 0 0-1.98-7.26 5.316 5.316 0 0 0-7.255 1.982 5.323 5.323 0 0 0 1.98 7.26 5.316 5.316 0 0 0 7.255-1.983Z"
              clipRule="evenodd"
            />
          </mask>
          <g mask="url(#copy-success-e)">
            <path fill="#E9252B" d="M144.883 161.723 202.342 61.04l-85.043-48.599-57.46 100.682 85.044 48.6Z" />
            <path
              fill="#3FBFE5"
              d="m121.518 103.296 15.276-26.767a4.647 4.647 0 0 0-.036-4.67 4.681 4.681 0 0 0-4.082-2.303l-46.265.575a4.646 4.646 0 0 0-2.453.73l18.016-31.57.043-.033a7.273 7.273 0 0 1 5.394-1.457 7.313 7.313 0 0 1 2.706.916l30.036 17.164a7.318 7.318 0 0 1 3.445 4.417 7.253 7.253 0 0 1-.7 5.537l-3.954 6.927a4.633 4.633 0 0 0 .009 4.624l23.044 40.156a4.7 4.7 0 0 0 1.71 1.717c.71.411 1.517.629 2.336.632a4.637 4.637 0 0 0 4.038-2.323l-5.801 10.165a7.263 7.263 0 0 1-4.412 3.376 7.338 7.338 0 0 1-5.528-.722l-30.036-17.165a7.36 7.36 0 0 1-3.438-4.4 7.29 7.29 0 0 1 .652-5.526Zm46.917-32.256a24.51 24.51 0 0 1-9.12-33.429l.5-.875 42.451 24.26-.532.93a24.475 24.475 0 0 1-33.299 9.113Zm-86.256 7.74a4.64 4.64 0 0 0-4.026 2.274l-.018.008-11.457 20.075 42.434 24.249c.292-.6.452-1.257.465-1.927a4.616 4.616 0 0 0-.554-2.296L86.17 81.117a4.643 4.643 0 0 0-3.991-2.337Z"
            />
            <path fill="#D1E3E7" d="m105.209 231.242 48.567-85.1-85.044-48.6-48.567 85.1 85.044 48.6Z" />
            <path
              fill="#E9252B"
              d="m57.635 186.235-8.048-14.008a1.641 1.641 0 0 1 1.368-2.398l16.174-.229a1.633 1.633 0 0 1 1.42.79 1.636 1.636 0 0 1 .028 1.627l-8.112 14.215a1.632 1.632 0 0 1-1.414.801 1.634 1.634 0 0 1-1.403-.821l-.013.023Zm-.624-30.919a9.33 9.33 0 0 0 .261 9.924 2.755 2.755 0 0 0 2.288 1.196l11.702-.131a1.375 1.375 0 0 0 1.086-.54 9.93 9.93 0 0 0 .728-1.078 9.308 9.308 0 0 0-3.35-12.723 9.29 9.29 0 0 0-12.715 3.352Zm11.885-20.184-6.252 10.955a.989.989 0 0 0 .027 1.019.981.981 0 0 0 .918.443 12.718 12.718 0 0 1 12.9 7.372.982.982 0 0 0 1.362.462.968.968 0 0 0 .381-.372l6.252-10.955c.134-.235.22-.494.255-.76a2.09 2.09 0 0 0-.056-.802 2.093 2.093 0 0 0-.359-.718 2.09 2.09 0 0 0-.606-.526l-11.978-6.844a2.05 2.05 0 0 0-2.803.765l-.04-.039Z"
            />
          </g>
        </g>
      </g>
      <path
        fill="#E0EBF0"
        stroke="#E0EBF0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.962"
        d="M120.738 240.161c24.295 0 44.172-19.891 44.172-44.202 0-24.31-19.877-44.201-44.172-44.201-24.294 0-44.172 19.891-44.172 44.201 0 24.311 19.878 44.202 44.172 44.202Z"
      />
      <path stroke="#B5CCD1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10.321" d="m101.972 195.958 12.5 12.509 25.046-25.018" />
      <defs>
        <clipPath id="copy-success-a">
          <path fill="#fff" d="M0 0h219.387v193.012H0z" transform="translate(10.309)" />
        </clipPath>
        <clipPath id="copy-success-b">
          <rect width="195.968" height="97.951" fill="#fff" rx="14.745" transform="rotate(-60.286 220.05 82.524) skewX(-.033)" />
        </clipPath>
        <clipPath id="copy-success-c">
          <rect width="195.968" height="97.951" fill="#fff" rx="14.745" transform="rotate(-60.286 194.066 77.608) skewX(-.033)" />
        </clipPath>
        <clipPath id="copy-success-d">
          <rect width="195.968" height="97.951" fill="#fff" rx="14.745" transform="rotate(-60.286 167.344 73.959) skewX(-.033)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CopySuccess
