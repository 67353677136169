import React from 'react'

export const ArrowLeft = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.5 16.6L7.06667 11.1667C6.425 10.525 6.425 9.47499 7.06667 8.83333L12.5 3.39999"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowLeft.displayName = 'ArrowLeft'
