import { useCallback, useEffect, useRef, useState } from 'react'
import { fetchHouses, fetchRegions, fetchStreets } from '../../../../../services/locations'
import { NotificationManager } from '../../../../../components/ui/Notifications'

interface IOptions extends ILocation {
  label: string
  value: number
}

interface IStore {
  regions: IOptions[]
  streets: {
    [key: number]: IOptions[]
  }
  houses: {
    [key: number]: IOptions[]
  }
}

const STORE: IStore = {
  regions: [],
  streets: {},
  houses: {},
}

const convertEntriesToOptions = (entries: ILocation[]): IOptions[] => {
  return entries.map((entry) => {
    return {
      ...entry,
      label: entry.name,
      value: entry.id,
    }
  })
}

const loadRegions = async () => {
  const { entries } = await fetchRegions()

  STORE.regions = convertEntriesToOptions(entries)

  return { ...STORE }
}

const loadStreets = async (regionId: number) => {
  const { entries } = await fetchStreets(regionId)

  STORE.streets[regionId] = convertEntriesToOptions(entries)

  return { ...STORE }
}

const loadHouses = async (regionId: number, streetId: number) => {
  const { entries } = await fetchHouses(regionId, streetId)

  STORE.houses[streetId] = convertEntriesToOptions(entries)

  return { ...STORE }
}

export const useLocations = () => {
  const errorsStore = useRef<Record<number, number>>({})
  const [state, setState] = useState(STORE)
  const [loadingState, setLoadingState] = useState<Record<number, boolean>>({})

  const pushError = useCallback((key: number) => {
    errorsStore.current[key] = (errorsStore.current[key] || 0) + 1
  }, [])

  const isBroken = useCallback((key: number) => {
    return errorsStore.current[key] === 2
  }, [])

  const pushLoadingState = useCallback(
    (key: number, value: boolean) => {
      setLoadingState((state) => ({
        ...state,
        [key]: value,
      }))
    },
    [setLoadingState],
  )

  useEffect(() => {
    if (state.regions.length === 0 && !loadingState[0] && !isBroken(0)) {
      pushLoadingState(0, true)

      loadRegions()
        .then(setState)
        .catch(() => {
          pushError(0)
          NotificationManager.error('Не удалось загрузить доступные города')
        })
        .then(() => pushLoadingState(0, false))
    }
  }, [])

  const getRegions = useCallback(() => {
    return state.regions || []
  }, [state])

  const getStreets = useCallback(
    (regionId: number) => {
      const streets = state.streets[regionId]

      if (streets === undefined && regionId && !loadingState[regionId] && !isBroken(regionId)) {
        pushLoadingState(regionId, true)

        loadStreets(regionId)
          .then(setState)
          .catch(() => {
            pushError(regionId)
            NotificationManager.error('Не удалось загрузить доступные улицы')
          })
          .then(() => pushLoadingState(regionId, false))

        return []
      }

      return streets || []
    },
    [state, loadingState],
  )

  const getHouses = useCallback(
    (regionId: number, streetId: number) => {
      const houses = state.houses[streetId]

      if (regionId && streetId && houses === undefined && !loadingState[streetId] && !isBroken(streetId)) {
        pushLoadingState(streetId, true)

        loadHouses(regionId, streetId)
          .then(setState)
          .catch(() => {
            pushError(streetId)
            NotificationManager.error('Не удалось загрузить доступные дома')
          })
          .then(() => pushLoadingState(streetId, false))
      }

      return houses || []
    },
    [state, loadingState],
  )

  return {
    getRegions,
    getStreets,
    getHouses,
    isLoadingRegions() {
      return Boolean(loadingState[0])
    },
    isLoadingStreets(regionId: number) {
      return Boolean(loadingState[regionId])
    },
    isLoadingHouses(streetId: number) {
      return Boolean(loadingState[streetId])
    },
  }
}
