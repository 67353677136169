import React from 'react'

// #518399
export const ViberLogo = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={16} {...props}>
    <path
      d="M14.057 1.561C13.66 1.196 12.053.028 8.47.011c0 0-4.222-.255-6.28 1.633C1.043 2.79.64 4.464.598 6.545c-.041 2.078-.096 5.974 3.66 7.03h.002l-.003 1.61s-.022.653.407.784c.518.16.826-.336 1.322-.87.272-.295.646-.724.931-1.053 2.568.214 4.543-.279 4.767-.353.518-.17 3.454-.544 3.93-4.44.494-4.007-.24-6.546-1.558-7.692Zm.435 7.41c-.403 3.253-2.782 3.458-3.22 3.599-.186.06-1.92.493-4.104.349 0 0-1.626 1.962-2.132 2.471-.08.08-.173.112-.234.096-.086-.022-.112-.125-.108-.275l.012-2.68c-.003 0-.003 0 0 0-3.178-.88-2.993-4.196-2.958-5.931.036-1.735.362-3.16 1.332-4.117C4.818.908 8.407 1.142 8.407 1.142c3.028.012 4.478.925 4.814 1.229 1.118.96 1.687 3.25 1.271 6.6Z"
      fill="currentColor"
    />
    <path
      d="M9.947 6.66a.203.203 0 0 1-.202-.192c-.035-.688-.359-1.024-1.018-1.06a.204.204 0 0 1 .022-.406c.871.048 1.358.548 1.402 1.444a.2.2 0 0 1-.192.211c-.006.004-.01.004-.012.004Z"
      fill="currentColor"
    />
    <path
      d="M10.984 7.006h-.003a.204.204 0 0 1-.199-.208c.016-.691-.182-1.251-.605-1.712-.42-.461-.999-.711-1.764-.765a.201.201 0 0 1-.185-.218.201.201 0 0 1 .217-.186c.865.064 1.547.365 2.033.897.49.534.727 1.203.71 1.994a.205.205 0 0 1-.204.198Z"
      fill="currentColor"
    />
    <path
      d="M12.046 7.422a.2.2 0 0 1-.201-.201c-.01-1.24-.365-2.184-1.088-2.891-.714-.698-1.62-1.053-2.686-1.063a.204.204 0 0 1 0-.406c1.175.01 2.173.403 2.964 1.178.794.774 1.2 1.844 1.213 3.175a.2.2 0 0 1-.201.208c.003 0 .003 0 0 0ZM8.775 9.167s.285.026.439-.163l.297-.375c.144-.186.493-.304.833-.115.188.106.531.317.742.474.227.166.688.55.692.553.22.186.272.458.121.75v.006a3.12 3.12 0 0 1-.62.765c-.004 0-.004.003-.007.003-.215.18-.426.282-.63.304-.026.007-.055.007-.093.007a.872.872 0 0 1-.27-.042l-.006-.01c-.32-.09-.851-.313-1.735-.803a10.301 10.301 0 0 1-1.46-.964 7.475 7.475 0 0 1-.656-.582L6.4 8.952l-.023-.022a47.338 47.338 0 0 0-.022-.022l-.022-.023a8.036 8.036 0 0 1-.583-.656 10.123 10.123 0 0 1-.964-1.46c-.49-.886-.714-1.418-.803-1.735l-.01-.006a.872.872 0 0 1-.041-.27c0-.037 0-.066.006-.092.026-.208.128-.416.304-.63 0-.004.003-.004.003-.007a3.05 3.05 0 0 1 .765-.621h.007c.288-.15.56-.1.749.122 0 0 .387.464.554.691.157.215.368.554.473.743.19.34.07.688-.115.832l-.374.298c-.19.153-.164.438-.164.438s.554 2.107 2.635 2.635Z"
      fill="currentColor"
    />
  </svg>
))
