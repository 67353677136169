import { IFormState } from './form.types'
import { STEPS } from '../../../constants/Home.constants'

export enum FINAL_STATUSES {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface FinalBlockOptions {
  status?: FINAL_STATUSES
  message?: string
}

export interface FinalBlockProps extends FinalBlockOptions {
  onChangeStep: (step: STEPS, patch?: Partial<IFormState>) => void
}
