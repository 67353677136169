import { useCallback } from 'react'

import { Container, Button, Heading, KeyContainer } from '../styles/Banner.sc'
import { Icon } from '../../../../../components/ui/Icon'
import { Text } from '../../../../../components/ui/Typography'
import { TailedArrowRight } from '../../../../../components/icons/src/TailedArrowRight'
import Key from '../../../../../components/images/Key'
import { clickBuyKeyEvent } from '../../../../../events/keys'

const OZON_KEY_LINK =
  'https://www.ozon.ru/product/klyuch-dlya-umnogo-domofona-dom-ru-238354072/?_bctx=CAQQutAC&asb2=o9qldPw-CvVP_nq0DeObxEBPlPtNadrTsPATxiN2Wd6njgYb_0HGauxQYxta2IQg&hs=1&miniapp=seller_43066&sh=azgZE0jvYw'

const Banner = () => {
  const handleClick = useCallback(() => {
    clickBuyKeyEvent()
  }, [])

  return (
    <Container target="_blank" href={OZON_KEY_LINK} onClick={handleClick}>
      <Heading level={6}>Закажите новый ключ</Heading>
      <Button>
        <Text size={14}>В магазин Ozon</Text>
        <Icon size={20} icon={<TailedArrowRight />} />
      </Button>
      <KeyContainer>
        <Key />
      </KeyContainer>
    </Container>
  )
}

export default Banner
