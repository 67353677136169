import { IFormState } from '../pages/home/components/form/types/form.types'
import { runtimeConfig } from '../config'

const PROPERTY_KEYS = {
  KEY_ORIGINAL: 'key_original',
  KEY_CLONE: 'key_clone',
  HOUSE_ID: 'house_id',
  OPERATOR_ID: 'operator_id',
  CITY: 'city',
  FLAT: 'flat',
}

const { YANDEX_METRICA_COUNTER_ID } = runtimeConfig

type ErrorStateProps = Partial<IFormState> & { errorCode?: number; errorMessage?: string }

const sendEvent = (eventName: string, props?: Record<string, any>) => {
  if (!YANDEX_METRICA_COUNTER_ID || !window.ym) {
    return
  }

  window.ym(Number(YANDEX_METRICA_COUNTER_ID), 'reachGoal', eventName, props)
}

export const openPageEvent = () => {
  sendEvent('key_clone_open_page')
}

export const clickBuyKeyEvent = () => {
  sendEvent('key_clone_order_key')
}

export const authStartEvent = () => {
  sendEvent('key_clone_auth_start')
}

export const authOkEvent = () => {
  sendEvent('key_clone_auth_ok')
}

export const addNewkeyStartEvent = () => {
  sendEvent('key_clone_add_newkey_start')
}

export const openAppdownloadEvent = () => {
  sendEvent('key_clone_open_appdownload')
}

export const keyHasBeenClonedEvent = (props: Partial<IFormState>) => {
  sendEvent('key_clone_add_newkey_ok', {
    [PROPERTY_KEYS.KEY_ORIGINAL]: props.keyCode,
    [PROPERTY_KEYS.KEY_CLONE]: props.cloneKeyCode,
    [PROPERTY_KEYS.HOUSE_ID]: props.houseId,
    [PROPERTY_KEYS.OPERATOR_ID]: props.operatorId,
    [PROPERTY_KEYS.CITY]: props.regionName,
    [PROPERTY_KEYS.FLAT]: props.flat,
  })
}

const ErrorsMessages = {
  key_clone_auth_key_not_found_err: /Ключ не идентифицирован. Попробуйте снова или обратитесь в контакт-центр или офис/,
  key_clone_auth_key_address_err: /По данному адресу добавление ключа не доступно/,
  key_clone_auth_key_occupied_err: /Ваш ключ \S+ уже активирован на адресе/,
  key_clone_auth_key_system_err: /Произошла ошибка при получение данных/,
  key_clone_auth_key_service_err: /В данный момент проверка ключа не доступна/,
}

/**
 * not used (yet?)
 *
  /Передан пустой номер квартиры/,
  /Передан не правильный идентификатор дома/,
  /Код ключа не заполнен/,
  /Код ключа \S+ введен неверно. Попробуйте снова или обратитесь в контакт-центр/,
  /По данному адресу добавление ключа не доступно/,
  /Общая ошибка работы системы/,
 */

export const keyCheckErrorEvent = (props: ErrorStateProps) => {
  const { errorMessage } = props

  const eventName = Object.keys(ErrorsMessages)
    .filter((errorCode) => ErrorsMessages[errorCode as keyof typeof ErrorsMessages].test(errorMessage ?? ''))
    .pop()

  if (eventName) {
    sendEvent(eventName, {
      [PROPERTY_KEYS.KEY_ORIGINAL]: props.keyCode,
      [PROPERTY_KEYS.HOUSE_ID]: props.houseId,
      [PROPERTY_KEYS.OPERATOR_ID]: props.operatorId,
      [PROPERTY_KEYS.CITY]: props.regionName,
      [PROPERTY_KEYS.FLAT]: props.flat,
    })
  }

  sendEvent('key_clone_auth_service_err', {
    [PROPERTY_KEYS.KEY_ORIGINAL]: props.keyCode,
    [PROPERTY_KEYS.HOUSE_ID]: props.houseId,
    [PROPERTY_KEYS.OPERATOR_ID]: props.operatorId,
    [PROPERTY_KEYS.CITY]: props.regionName,
    [PROPERTY_KEYS.FLAT]: props.flat,
  })
}
