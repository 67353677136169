import React from 'react'

export const Sort = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.5 5.83333H17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 10H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8.33325 14.1667H11.6666" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
))

Sort.displayName = 'Sort'
