import styled, { css } from 'styled-components'

import { Icon as OriginalIcon } from '../../Icon'

import { NotificationProps, Type } from '../types/Notifications.types'

export const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: ${({ theme }) => theme.zindex.tooltip};
  top: 20px;
  right: 20px;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  pointer-events: none;
`

export const Notification = styled.div<Partial<NotificationProps>>`
  display: flex;
  width: auto;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  pointer-events: auto;
  max-width: 400px;
  border-radius: 12px;
  padding: 12px 20px;
  box-shadow: ${({ theme }) => theme.shadow.medium};
  margin-bottom: 12px;

  &.enter {
    opacity: 0;
    transform: translateX(100%);
    max-height: 0;
  }
  &.enter-active {
    opacity: 1;
    max-height: 500px;
    transform: translateX(0);
    transition: all 300ms ${({ theme }) => theme.easing.inOut};
  }
  &.exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: all 300ms ${({ theme }) => theme.easing.out};
  }
`

const getColorStyles = (type?: Type) => {
  switch (type) {
    case 'success':
      return css`
        color: ${({ theme }) => theme.colors.green};
      `
    case 'error':
      return css`
        color: ${({ theme }) => theme.colors.red};
      `
  }
}

export const Icon = styled(OriginalIcon)<Partial<NotificationProps>>`
  ${({ type }) => getColorStyles(type)}
`
