import * as React from 'react'

import { MenuProps } from '../types/Menu.types'
import { Container } from '../styles/Menu.sc'

/**
 * @description The Menu component shows a list of actions that user can take
 *
 * @component
 * @example
 * ```jsx
 * <Menu>
 *   <Menu.Item>1 год</Menu.Item>
 *   <Menu.Item>2 года</Menu.Item>
 * </Menu>
 * ```
 */

const Menu = React.forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  const { children, ...restProps } = props

  return (
    <Container ref={ref} data-qa="Menu" {...restProps}>
      {children}
    </Container>
  )
})

Menu.displayName = 'Menu'
Menu.defaultProps = {}

export default Menu
