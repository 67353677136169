const CopyFailed = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 240 249">
      <rect width="240" height="5.892" y="192.947" fill="#B5CCD1" rx="2.946" />
      <g clipPath="url(#copy-failed-a)">
        <g clipPath="url(#copy-failed-b)">
          <path
            fill="#B5CCD1"
            fillRule="evenodd"
            d="M136.441 62.516 39.307 232.66l85.044 48.583 97.134-170.144-85.044-48.583Zm7.802 23.531a5.32 5.32 0 0 0-1.98-7.257 5.32 5.32 0 0 0-5.274 9.24 5.315 5.315 0 0 0 7.254-1.983Z"
            clipRule="evenodd"
          />
        </g>
        <g clipPath="url(#copy-failed-c)">
          <path
            fill="#C1D5DA"
            fillRule="evenodd"
            d="M127.604 37.475 30.469 207.619l85.044 48.583 97.134-170.144-85.043-48.583Zm7.802 23.531a5.317 5.317 0 1 0-9.235-5.276 5.317 5.317 0 1 0 9.235 5.276Z"
            clipRule="evenodd"
          />
        </g>
        <g clipPath="url(#copy-failed-d)">
          {/*<mask id="copy-failed-e" width="183" height="220" x="20" y="12" maskUnits="userSpaceOnUse" style="mask-type:alpha">*/}
          <mask id="copy-failed-e" width="183" height="220" x="20" y="12" maskUnits="userSpaceOnUse">
            <path
              fill="#C4C4C4"
              fillRule="evenodd"
              d="M117.298 12.437 20.163 182.581l85.044 48.584L202.341 61.02l-85.043-48.583Zm7.802 23.531a5.317 5.317 0 1 0-9.235-5.275 5.317 5.317 0 1 0 9.235 5.275Z"
              clipRule="evenodd"
            />
          </mask>
          <g mask="url(#copy-failed-e)">
            <path fill="#E9252B" d="M144.883 161.669 202.342 61.02l-85.043-48.583-57.46 100.648 85.044 48.584Z" />
            <path
              fill="#3FBFE5"
              d="m121.518 103.262 15.276-26.758a4.644 4.644 0 0 0-.036-4.668 4.682 4.682 0 0 0-4.082-2.302l-46.265.573a4.646 4.646 0 0 0-2.453.73l18.016-31.559.043-.033a7.274 7.274 0 0 1 5.394-1.456 7.312 7.312 0 0 1 2.706.915l30.036 17.158a7.318 7.318 0 0 1 3.445 4.416 7.249 7.249 0 0 1-.7 5.535l-3.954 6.925a4.63 4.63 0 0 0 .009 4.623l23.044 40.142a4.706 4.706 0 0 0 1.71 1.716c.71.411 1.517.629 2.336.632a4.635 4.635 0 0 0 4.038-2.322l-5.801 10.162a7.27 7.27 0 0 1-4.412 3.375 7.338 7.338 0 0 1-5.528-.723l-30.036-17.158a7.362 7.362 0 0 1-3.438-4.399 7.284 7.284 0 0 1 .652-5.524Zm46.917-32.246a24.503 24.503 0 0 1-9.12-33.418l.5-.874 42.451 24.251-.532.931a24.482 24.482 0 0 1-33.299 9.11Zm-86.256 7.738a4.64 4.64 0 0 0-4.026 2.273l-.018.008-11.457 20.068 42.434 24.241a4.603 4.603 0 0 0 .465-1.926 4.61 4.61 0 0 0-.554-2.295L86.17 81.09a4.641 4.641 0 0 0-3.991-2.336Z"
            />
            <path fill="#D1E3E7" d="m105.209 231.165 48.567-85.072L68.732 97.51l-48.567 85.072 85.044 48.583Z" />
            <path
              fill="#E9252B"
              d="m57.635 186.173-8.048-14.004a1.635 1.635 0 0 1 .03-1.571 1.638 1.638 0 0 1 1.338-.825l16.174-.23a1.625 1.625 0 0 1 1.65 1.599 1.63 1.63 0 0 1-.202.817l-8.112 14.21c-.146.247-.352.45-.6.59a1.634 1.634 0 0 1-2.216-.609l-.014.023Zm-.624-30.909a9.322 9.322 0 0 0 .261 9.921 2.764 2.764 0 0 0 2.288 1.195l11.702-.131a1.39 1.39 0 0 0 1.086-.539c.266-.343.509-.704.728-1.078a9.304 9.304 0 0 0-3.35-12.719 9.296 9.296 0 0 0-12.715 3.351Zm11.885-20.177-6.252 10.952a.985.985 0 0 0 .027 1.018.979.979 0 0 0 .917.443 12.71 12.71 0 0 1 12.9 7.37.985.985 0 0 0 .845.579.982.982 0 0 0 .9-.489l6.251-10.952c.134-.235.22-.494.255-.76a2.08 2.08 0 0 0-.056-.801 2.075 2.075 0 0 0-.359-.718 2.09 2.09 0 0 0-.606-.526l-11.978-6.842a2.06 2.06 0 0 0-2.279.16c-.212.164-.39.37-.524.605l-.04-.039Z"
            />
          </g>
        </g>
      </g>
      <path
        fill="#FDE1E1"
        stroke="#FDE1E1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.962"
        d="M121.008 240.163c24.294 0 44.172-19.884 44.172-44.187 0-24.302-19.878-44.186-44.172-44.186-24.295 0-44.172 19.884-44.172 44.186 0 24.303 19.877 44.187 44.172 44.187Z"
      />
      <path
        fill="#FF9494"
        fillRule="evenodd"
        stroke="#FF9494"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        d="M103.588 178.529a2.01 2.01 0 0 1 2.842 0L121 193.094l14.57-14.565a2.01 2.01 0 0 1 2.842 2.84l-14.571 14.565 14.571 14.566a2.009 2.009 0 1 1-2.842 2.84L121 198.775l-14.57 14.565a2.009 2.009 0 1 1-2.842-2.84l14.571-14.566-14.571-14.565a2.009 2.009 0 0 1 0-2.84Z"
        clipRule="evenodd"
      />
      <defs>
        <clipPath id="copy-failed-a">
          <path fill="#fff" d="M0 0h219.387v192.948H0z" transform="translate(10.309)" />
        </clipPath>
        <clipPath id="copy-failed-b">
          <rect width="195.918" height="97.942" fill="#fff" rx="14.745" transform="rotate(-60.278 220.016 82.48) skewX(-.016)" />
        </clipPath>
        <clipPath id="copy-failed-c">
          <rect width="195.918" height="97.943" fill="#fff" rx="14.745" transform="rotate(-60.278 194.037 77.57) skewX(-.016)" />
        </clipPath>
        <clipPath id="copy-failed-d">
          <rect width="195.918" height="97.942" fill="#fff" rx="14.745" transform="rotate(-60.278 167.318 73.925) skewX(-.016)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CopyFailed
