import { Container, SupportText, SupportLink } from '../styles/Support.sc'

const Support = () => {
  return (
    <Container>
      <SupportText size={14}>Если возникают трудности, обратитесь в поддержку</SupportText>
      {/* @ts-ignore */}
      <SupportLink as="a" size={14} href="mailto:help@domru.ru">
        help@domru.ru
      </SupportLink>
    </Container>
  )
}

export default Support
