import { FINAL_STATUSES, FinalBlockProps } from '../types/FinalBlock.types'
import { Button, Container, FinalStatusImageContainer, Heading, Text } from '../styles/FinalBlock.sc'
import CopySuccess from '../../../../../components/images/CopySuccess'
import CopyFailed from '../../../../../components/images/CopyFailed'
import { useCallback } from 'react'
import { STEPS } from '../../../constants/Home.constants'

const FinalBlock = (props: FinalBlockProps) => {
  const { message, status, onChangeStep } = props

  if (!status) {
    return null
  }

  let imageContent = null
  let headerContent = null
  let messageContent = null
  let actionButton = null
  let backButton = null

  const handleClickHome = useCallback(() => {
    onChangeStep(STEPS.KEY_FORM, {
      cloneKeyCode: undefined,
    })
  }, [onChangeStep])

  const handleClickOneMoreKey = useCallback(() => {
    onChangeStep(STEPS.KEY_COPY_FORM, {
      cloneKeyCode: undefined,
    })
  }, [onChangeStep])

  const handleClickRetry = useCallback(() => {
    onChangeStep(STEPS.KEY_COPY_FORM)
  }, [onChangeStep])

  switch (status) {
    case FINAL_STATUSES.SUCCESS: {
      imageContent = <CopySuccess />
      headerContent = 'Ключ готов'

      actionButton = (
        <div>
          <Button block size={48} variant="action" onClick={handleClickOneMoreKey}>
            Копировать еще ключ
          </Button>
        </div>
      )

      backButton = (
        <Button block size={48} variant="secondary" onClick={handleClickHome}>
          На главную
        </Button>
      )
      break
    }

    case FINAL_STATUSES.FAILED: {
      imageContent = <CopyFailed />
      headerContent = 'Не удалось создать копию'

      actionButton = (
        <Button block size={48} variant="action" onClick={handleClickRetry}>
          Попробовать еще раз
        </Button>
      )
      break
    }
  }

  if (message) {
    messageContent = <Text size={22}>{message}</Text>
  }

  return (
    <Container>
      <FinalStatusImageContainer>{imageContent}</FinalStatusImageContainer>
      <Heading level={3}>{headerContent}</Heading>
      {messageContent}
      {actionButton}
      {backButton}
    </Container>
  )
}

export default FinalBlock
