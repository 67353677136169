import _pick from 'lodash/pick'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useField, useForm } from 'react-final-form-hooks'

import { KeyFormProps } from '../types/KeyForm.types'
import { keyCheckErrorEvent } from '../../../../../events/keys'
import { checkKey } from '../../../../../services/keys'
import { Button } from '../../../../../components/ui/Button'
import { Input } from '../../../../../components/ui/Input'
import { Autocomplete } from '../../../../../components/ui/Autocomplete'
import { NotificationManager } from '../../../../../components/ui/Notifications'
import { InfoCircle } from '../../../../../components/icons/src/InfoCircle'
import {
  AddressHeading,
  BlueIcon,
  Container,
  Heading,
  InputGroup,
  KeyCodeFieldWrapper,
  SubHeading,
  Text80,
  TooltipTriggerText,
  StyledTooltip,
} from '../styles/KeyForm.sc'
import { useLocations } from '../hooks/locations'
import { NAMES, PLACEHOLDERS, VALIDATORS, decodeValues, encodeValues } from './forms.helpers'
import KeyInput from './KeyInput'
import Field from './Field'
import { TooltipContent } from './TooltipContent'
import { authStartEvent } from '../../../../../events/keys'

const KeyForm = (props: KeyFormProps) => {
  const { state, onFinish, token, showCaptcha } = props

  const locations = useLocations()
  const [loading, setLoading] = useState(false)
  const [captchaRequested, setCaptchaRequested] = useState(false)

  const { form, handleSubmit, invalid } = useForm({
    initialValues: encodeValues(state),

    async onSubmit(data) {
      if (loading) {
        return
      }

      if (!token) {
        showCaptcha()
        setCaptchaRequested(true)

        return
      }

      const recaptcha = { token }

      setLoading(true)

      authStartEvent()

      // All values
      const decoded = decodeValues(data)

      try {
        // Only needed for request
        const payload = _pick(decoded, ['operatorId', 'keyCode', 'houseId', 'flat'])

        await checkKey(payload, { recaptcha })

        onFinish(decoded)
      } catch (error: any) {
        let message = 'Неизвестная ошибка'

        if (error.isAxiosError) {
          const { content } = error?.response?.data || {}

          if (content && content.errorMessage) {
            message = content.errorMessage
          }

          if (content && content.errorCode) {
            keyCheckErrorEvent({
              ...decoded,
              errorCode: content.errorCode,
              errorMessage: content.errorMessage,
            })
          }

          NotificationManager.error(message)
        }
      }

      setLoading(false)
    },
  })

  useEffect(() => {
    if (captchaRequested && token) {
      setCaptchaRequested(false)
      handleSubmit()
    }
  }, [token, captchaRequested, handleSubmit])

  const keyCode = useField(NAMES.keyCode, form, VALIDATORS[NAMES.keyCode])
  const regionId = useField(NAMES.regionId, form, VALIDATORS[NAMES.regionId])
  const streetId = useField(NAMES.streetId, form, VALIDATORS[NAMES.streetId])
  const houseId = useField(NAMES.houseId, form, VALIDATORS[NAMES.houseId])
  const flat = useField(NAMES.flat, form, VALIDATORS[NAMES.flat])

  const selectedRegionId = regionId.input.value
  const selectedStreetId = streetId.input.value

  const isLoadingRegions = locations.isLoadingRegions()
  const isLoadingStreets = locations.isLoadingStreets(selectedRegionId)
  const isLoadingHouses = locations.isLoadingHouses(selectedStreetId)

  const regions = useMemo(() => locations.getRegions(), [isLoadingRegions])
  const streets = useMemo(() => locations.getStreets(selectedRegionId), [selectedRegionId, isLoadingStreets])
  const houses = useMemo(() => locations.getHouses(selectedRegionId, selectedStreetId), [selectedRegionId, selectedStreetId, isLoadingHouses])

  const handleSelectRegion = useCallback((option) => {
    form.change(NAMES.operatorId, option.operatorId)
    form.change(NAMES.regionName, option.label)
  }, [])

  useEffect(() => {
    if (regionId.meta.active) {
      // clear street / house / flat if region change
      form.change(NAMES.streetId, undefined)
      form.change(NAMES.houseId, undefined)
      form.change(NAMES.flat, undefined)
    }
  }, [selectedRegionId, regionId.meta.active])

  useEffect(() => {
    if (streetId.meta.active) {
      // clear houseId and flat if street change
      form.change(NAMES.houseId, undefined)
      form.change(NAMES.flat, undefined)
    }
  }, [selectedStreetId, streetId.meta.active])

  return (
    <Container as="form" onSubmit={handleSubmit}>
      <Heading level={5}>Введите номер старого ключа</Heading>
      <SubHeading>
        <BlueIcon size={16} icon={<InfoCircle />} />
        <Text80 size={12}>Активация ключей Цифрал-сервис недоступна</Text80>
      </SubHeading>
      <KeyCodeFieldWrapper>
        <Field final={keyCode} as={KeyInput} placeholder={PLACEHOLDERS[NAMES.keyCode]} />
      </KeyCodeFieldWrapper>
      <StyledTooltip title={<TooltipContent />} positioner={{ placement: 'bottom', offset: [-5, 10] }}>
        <TooltipTriggerText size={12}>Где найти номер ключа?</TooltipTriggerText>
      </StyledTooltip>

      <AddressHeading level={5}>Введите адрес</AddressHeading>

      <Field
        final={regionId}
        as={Autocomplete}
        loading={isLoadingRegions}
        placeholder={PLACEHOLDERS[NAMES.regionId]}
        options={regions}
        onSelect={handleSelectRegion}
      />

      <Field as={Autocomplete} final={streetId} loading={isLoadingStreets} placeholder={PLACEHOLDERS[NAMES.streetId]} options={streets} />

      <InputGroup>
        <Field as={Autocomplete} final={houseId} loading={isLoadingHouses} options={houses} placeholder={PLACEHOLDERS[NAMES.houseId]} />

        <Field as={Input} final={flat} placeholder={PLACEHOLDERS[NAMES.flat]} />
      </InputGroup>

      <Button disabled={invalid} block size={48} variant="action" loading={loading}>
        Продолжить
      </Button>
    </Container>
  )
}

export default KeyForm
