import * as React from 'react'
import InputMask from 'react-input-mask'
import { isDefined, safeInvoke } from '@r1team/react-utils'

import { InputProps } from '../../Input/types/Input.types'
import { Input } from '../../Input'

import { MaskInputProps } from '../types/MaskInput.types'
/**
 * @description The Mask Input component allows user to type in text with mask
 *
 * @component
 * @example
 * ```jsx
 * <MaskInput placeholder="Дата" mask="99/99/9999" value={value} onChange={handleChange} />
 * ```
 */
const MaskInput = React.forwardRef<HTMLInputElement, MaskInputProps>((props, ref) => {
  const { defaultValue, value, className, onChange, ...restProps } = props

  const [innerValue, setInnerValue] = React.useState(value || defaultValue)

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInnerValue(event.currentTarget.value)
    safeInvoke(onChange, event)
  }

  React.useEffect(() => {
    isDefined(value) && setInnerValue(value)
  }, [value])

  return (
    <InputMask value={innerValue} onChange={handleChange} {...restProps}>
      {(inputProps: InputProps) => <Input ref={ref} data-qa="MaskInput" className={className} {...inputProps} />}
    </InputMask>
  )
})

MaskInput.displayName = 'MaskInput'
MaskInput.defaultProps = {}

export default MaskInput
