import { useCallback } from 'react'
import MaskInput from '../../../../../components/ui/MaskInput/src/MaskInput'
import { MaskInputProps } from '../../../../../components/ui/MaskInput/types/MaskInput.types'

const MASK = 'XXXXXXXX'
const FORMAT = {
  X: '[0-9A-Fa-f]',
}

const KeyInput = (props: MaskInputProps) => {
  const onChange = useCallback(
    (e) => {
      e.target.value = e.target.value.toUpperCase()

      if (props.onChange) {
        props.onChange(e)
      }
    },
    [props.onChange],
  )

  return <MaskInput {...props} mask={MASK} formatChars={FORMAT} maskChar="" onChange={onChange} />
}

export default KeyInput
