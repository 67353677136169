import * as React from 'react'

import { Text } from '../../Typography'
import { HelperTextProps } from '../types/FormControl.types'
import { ControlContext } from './FormControl'
import { Helper } from '../styles/FormControl.sc'

/**
 * @description Skeleton Avatar component.
 *
 * @component
 * @example
 * ```jsx
 * <Skeleton.Avatar />
 * ```
 */
const HelperText = (props: HelperTextProps) => {
  const { className, children, ...restProps } = props
  const context = React.useContext(ControlContext)

  return (
    <Helper className={className} {...context} {...restProps}>
      <Text nowrap truncate size={12}>
        {children}
      </Text>
    </Helper>
  )
}

export default HelperText
