import * as React from 'react'
import { noop } from '@r1team/react-utils'

import { SwitchProps } from '../types/Switch.types'
import { Container, Input, Control, Handler, Label } from '../styles/Switch.sc'

/**
 * @description The Switch component is used to switch between two options and the result of the change is immediate
 *
 * @component
 * @example
 * ```jsx
 * <Switch>content</Switch>
 * ```
 */
const Switch = React.forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const { name, value, checked, disabled, className, children, onChange, ...restProps } = props

  return (
    <Container data-qa="Switch" checked={checked} disabled={disabled} className={className}>
      <Input ref={ref} type="checkbox" name={name} value={value} checked={checked} disabled={disabled} onChange={onChange} {...restProps} />
      <Control checked={checked} disabled={disabled}>
        <Handler checked={checked} disabled={disabled} />
      </Control>
      {children ? <Label>{children}</Label> : null}
    </Container>
  )
})

Switch.defaultProps = {
  checked: false,
  onChange: noop,
}

export default Switch
