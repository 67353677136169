import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: block;
  width: 100%;
  background: rgb(238, 238, 239);
  padding: 40px 0;
  margin-top: 50px;
`

export const FooterInner = styled.div`
  display: block;
  max-width: 1644px;
  padding: 0 8.4%;
  margin: 0 auto;
`

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;

  @media (max-width: 1279px) {
    grid-template-columns: 100%;
    justify-content: center;
  }
`

export const FooterLeft = styled.div`
  display: block;
  padding: 0 42px 34px 0;
`

export const LinksContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 31px;
  gap: 15%;
  margin-top: 14px;

  @media (max-width: 1279px) {
    justify-content: space-between;
  }
`

export const Links = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`

export const LinksItem = styled.li`
  display: block;
`

export const SocialLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: rgb(81, 131, 153);
  transition: all 0.25s ease 0s;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  column-gap: 12px;
  margin-bottom: 18px;
`

export const JustLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  letter-spacing: 0.01em;
  color: rgb(116, 118, 122);
  text-decoration: none;
`

export const PhoneContainer = styled.div`
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
  flex-direction: column;
  margin-bottom: 8px;
`

export const Phone = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: rgb(116, 118, 122);
  transition: all 0.25s ease 0s;
  cursor: pointer;
  margin-bottom: 4px;
  text-decoration: none;
`

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SocialsHeader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(116, 118, 122);
  margin-bottom: 10px;
`

export const SocialsList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 24px;
`

export const SocialsListItem = styled.li`
  display: flex;
  list-style: none;
`

export const SocialsListLink = styled.a`
  width: 24px;
  height: 24px;
  opacity: 1;
  letter-spacing: 0.01em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: #181b21;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  border-radius: 4px;
`

export const VKSocialLinkIcon = styled.svg`
  width: 15px;
`

export const FooterRight = styled.div`
  justify-self: flex-end;
  padding-bottom: 34px;

  @media (max-width: 1279px) {
    justify-self: flex-start;
  }
`

export const Copyrights = styled.div`
  display: block;
  border-top: 1px solid #c5c6c7;
`

export const CopyrightsInner = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: rgb(116, 118, 122);

  a {
    color: rgb(116, 118, 122);
  }
`

export const CompanyName = styled.p`
  margin-top: 2px;
`
