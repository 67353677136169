import React from 'react'

export const Check = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5607 8C18.8536 8.29289 18.8536 8.76777 18.5607 9.06066L11.2463 16.0637C10.9534 16.3565 10.4786 16.3566 10.1857 16.0638L5.84159 11.7211C5.54864 11.4282 5.54854 10.9534 5.84137 10.6604C6.1342 10.3674 6.60907 10.3673 6.90203 10.6602L10.7158 14.4728L17.5 8C17.7929 7.70711 18.2678 7.70711 18.5607 8Z"
      fill="currentColor"
    />
  </svg>
))

Check.displayName = 'Check'
