import * as React from 'react'

import { MenuProps } from './types/Menu.types'
import MenuRoot from './src/Menu'
import MenuItem from './src/MenuItem'

interface CompoundedComponent extends React.ForwardRefExoticComponent<MenuProps & React.RefAttributes<HTMLElement>> {
  Item: typeof MenuItem
}

const Menu = MenuRoot as CompoundedComponent

Menu.Item = MenuItem

export { Menu }
