import styled from 'styled-components'
import { Text } from '../../../components/ui/Typography'

export const ContentBlock = styled.div`
  margin-bottom: ${(props: { indent?: number }) => props.indent || 24}px;
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.div`
  flex-grow: 1;
`

export const SubHeading = styled(Text)`
  margin-top: 8px;
`
