import * as React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Portal } from '../../Portal'

import { NotificationProps } from '../types/Notifications.types'
import { Container } from '../styles/Notifications.sc'

import NotificationManager from './NotificationManager'
import Notification from './Notification'

/**
 * @description Display a notification message globally
 *
 * @component
 * @example
 * ```jsx
 * <Notifications />
 * ```
 */
const Notifications = React.memo(() => {
  const [notifications, setNotifications] = React.useState<NotificationProps[]>([])

  React.useEffect(() => {
    NotificationManager.addChangeListener(handleStoreChange)

    return () => {
      NotificationManager.removeChangeListener(handleStoreChange)
    }
  }, [])

  React.useEffect(() => {
    setNotifications(NotificationManager.notifications)
  }, [])

  const handleClose = React.useCallback((id) => {
    NotificationManager.remove(id)
  }, [])

  const items = React.useMemo(() => {
    return notifications.map((notification) => {
      return (
        <CSSTransition key={notification.id} timeout={300} unmountOnExit>
          <Notification {...notification} onClose={handleClose} />
        </CSSTransition>
      )
    })
  }, [notifications])

  const handleStoreChange = (items: NotificationProps[]) => {
    setNotifications(items)
  }

  return (
    <Portal>
      <Container data-qa="Notifications">
        <TransitionGroup>{items}</TransitionGroup>
      </Container>
    </Portal>
  )
})

Notifications.displayName = 'Notifications'

export default Notifications
