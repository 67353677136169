import styled, { css } from 'styled-components'
import { lighten } from 'polished'

import { getSecondaryShade01Color, getWhiteShade022Color, getTextAlpha02Color, getGrayTint09Color, getRedTint04Color } from '../../../../styles/utils'

import { CheckboxSize } from '../types/Checkbox.types'

export const Container = styled.label<{
  checked?: boolean
  disabled?: boolean
  invalid?: boolean
}>`
  display: inline-flex;
  vertical-align: top;
  cursor: pointer;
  user-select: none;
  gap: 12px;

  ${({ invalid, checked }) =>
    !invalid &&
    !checked &&
    css`
      &:hover {
        ${Control} {
          border-color: ${getGrayTint09Color};
        }
      }
    `}
  gap: 12px;

  ${({ checked, disabled, invalid }) =>
    checked &&
    !disabled &&
    !invalid &&
    css`
      &:hover {
        ${Control} {
          color: ${getGrayTint09Color};
          background: ${({ theme }) => lighten(0.17, theme.colors.white)};
          border-color: ${getGrayTint09Color};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: inherit;
      cursor: not-allowed;
      pointer-events: none;
    `}
`

export const Input = styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0;
`

const getSizeStyles = (size?: CheckboxSize) => {
  switch (size) {
    case 16:
      return css`
        width: 16px;
        height: 16px;
      `
    case 20:
      return css`
        width: 20px;
        height: 20px;
      `
    case 24:
      return css`
        width: 24px;
        height: 24px;
      `
  }
}

export const Control = styled.div<{
  checked?: boolean
  disabled?: boolean
  invalid?: boolean
  size?: CheckboxSize
}>`
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ${({ theme }) => theme.easing.inOutQuint};
  border-width: 2px;
  border-style: solid;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${getSecondaryShade01Color};
  will-change: background-color, border-color;
  border-radius: 6px;

  ${({ size }) => getSizeStyles(size)}

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${({ theme }) => theme.colors.gray};
      background-color: ${({ theme }) => theme.colors.gray};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${getWhiteShade022Color};
    `}

  ${({ checked, disabled }) =>
    checked &&
    disabled &&
    css`
      color: ${getTextAlpha02Color};
      border-color: ${getWhiteShade022Color};
      background-color: ${getWhiteShade022Color};
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      color: ${getRedTint04Color};
      border-color: ${getRedTint04Color};
      background-color: ${({ theme }) => theme.colors.white};
    `}
`

export const Label = styled.span<{
  disabled?: boolean
}>`
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${getWhiteShade022Color};
    `}
`
