import * as React from 'react'
import { noop } from '@r1team/react-utils'

import { RadioProps } from '../types/Radio.types'
import { Container, Input, Control, Icon, Label } from '../styles/Radio.sc'

/**
 * @description Radio component.
 *
 * @component
 * @example
 * ```jsx
 * <Radio name="radio" value="default" label="Radio default" checked disabled />
 * ```
 */
const Radio = React.forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { size, checked, disabled, invalid, className, children, onChange, ...restProps } = props

  return (
    <Container data-qa="Radio" checked={checked} invalid={invalid} disabled={disabled} className={className}>
      <Input ref={ref} type="radio" checked={checked} disabled={disabled} onChange={onChange} {...restProps} />
      <Control size={size} checked={checked} invalid={invalid} disabled={disabled}>
        {checked ? <Icon size={size} invalid={invalid} disabled={disabled} /> : null}
      </Control>
      {children ? <Label>{children}</Label> : null}
    </Container>
  )
})

Radio.displayName = 'Radio'
Radio.defaultProps = {
  size: 20,
  checked: false,
  onChange: noop,
}

export default Radio
