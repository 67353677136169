import styled, { css, keyframes } from 'styled-components'

import { Menu as OriginalMenu } from '../../Menu'
import { Icon as OriginalIcon } from '../../Icon'

const animation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`

export const Container = styled.div`
  width: 100%;
`

export const Menu = styled(OriginalMenu)`
  max-height: 341px;
  overflow-y: auto;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadow.medium};
  padding: 16px 0;
  animation: ${animation} 300ms ${({ theme }) => theme.easing.inOut};
`

export const Icon = styled(OriginalIcon)<{ active?: boolean }>`
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      transform: rotate(180deg);
      transition: transform 150ms cubic-bezier(0.86, 0, 0.07, 1);
    `}
`
