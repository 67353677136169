const GooglePlay = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 135 40">
      <rect width="135" height="40" fill="#000" rx="6" />
      {/*<rect width="134" height="39" x=".5" y=".5" stroke="#A6A6A6" rx="5.5"/>*/}
      <path
        fill="#fff"
        d="M68.14 21.75A4.26 4.26 0 1 0 72.41 26a4.19 4.19 0 0 0-4.27-4.25Zm0 6.83a2.58 2.58 0 1 1 2.4-2.58 2.46 2.46 0 0 1-2.4 2.58Zm-9.32-6.83A4.26 4.26 0 1 0 63.09 26a4.192 4.192 0 0 0-4.27-4.25Zm0 6.83a2.58 2.58 0 1 1 2.4-2.58 2.46 2.46 0 0 1-2.4 2.58Zm-11.08-5.52v1.8h4.32a3.77 3.77 0 0 1-1 2.27 4.42 4.42 0 0 1-3.34 1.32 4.8 4.8 0 0 1 0-9.6A4.64 4.64 0 0 1 51 20.14l1.27-1.27a6.3 6.3 0 0 0-4.53-1.82 6.61 6.61 0 1 0 0 13.21 6.07 6.07 0 0 0 4.61-1.85 6 6 0 0 0 1.56-4.23 6.264 6.264 0 0 0-.09-1.12h-6.08Zm45.31 1.4a4 4 0 0 0-3.64-2.71 4 4 0 0 0-4 4.25 4.23 4.23 0 0 0 7.76 2.37l-1.45-1a2.41 2.41 0 0 1-2.09 1.17 2.15 2.15 0 0 1-2.06-1.29l5.69-2.35-.21-.44Zm-5.8 1.42a2.338 2.338 0 0 1 2.23-2.49 1.64 1.64 0 0 1 1.57.91l-3.8 1.58ZM82.63 30h1.87V17.5h-1.87V30Zm-3.06-7.3h-.07a2.94 2.94 0 0 0-2.24-1 4.26 4.26 0 0 0 0 8.51 2.88 2.88 0 0 0 2.24-1h.07v.61c0 1.63-.87 2.5-2.27 2.5a2.36 2.36 0 0 1-2.15-1.51l-1.62.67A4 4 0 0 0 77.3 34c2.19 0 4-1.29 4-4.43V22h-1.73v.7Zm-2.15 5.88a2.58 2.58 0 0 1 0-5.15A2.39 2.39 0 0 1 79.7 26a2.38 2.38 0 0 1-2.28 2.58Zm24.39-11.08h-4.48V30h1.87v-4.74h2.61a3.889 3.889 0 1 0 0-7.76Zm0 6H99.2v-4.26h2.65a2.14 2.14 0 0 1 0 4.28l-.04-.02Zm11.54-1.79a3.49 3.49 0 0 0-3.33 1.91l1.65.69a1.784 1.784 0 0 1 1.71-.91 1.804 1.804 0 0 1 1.792.931c.114.21.184.441.208.679v.12a4.18 4.18 0 0 0-1.95-.48c-1.78 0-3.6 1-3.6 2.82a2.898 2.898 0 0 0 .973 2.034 2.892 2.892 0 0 0 2.137.716 2.65 2.65 0 0 0 2.38-1.22h.06v1h1.8v-4.81c0-2.19-1.66-3.46-3.79-3.46l-.04-.02Zm-.23 6.85c-.61 0-1.46-.31-1.46-1.06 0-1 1.06-1.34 2-1.34a3.32 3.32 0 0 1 1.7.42 2.259 2.259 0 0 1-2.2 2l-.04-.02ZM123.74 22l-2.14 5.42h-.06L119.32 22h-2l3.33 7.58-1.9 4.21h1.95L125.82 22h-2.08Zm-16.8 8h1.86V17.5h-1.86V30Z"
      />
      <path fill="url(#google-play-a)" d="M10.44 7.54a2 2 0 0 0-.47 1.4v22.12a1.94 1.94 0 0 0 .47 1.4l.07.08L22.9 20.15v-.3L10.51 7.47l-.07.07Z" />
      <path fill="url(#google-play-b)" d="m27 24.28-4.1-4.13v-.3l4.1-4.13.09.06L32 18.56c1.4.79 1.4 2.09 0 2.89l-4.89 2.78-.11.05Z" />
      <path fill="url(#google-play-c)" d="M27.12 24.22 22.9 20 10.44 32.46a1.62 1.62 0 0 0 2.07.07l14.61-8.31" />
      <path fill="url(#google-play-d)" d="m27.12 15.78-14.61-8.3a1.61 1.61 0 0 0-2.07.06L22.9 20l4.22-4.22Z" />
      <path fill="#000" d="m27 24.13-14.49 8.25a1.66 1.66 0 0 1-2 0l-.07.07.07.08a1.66 1.66 0 0 0 2 0l14.61-8.31-.12-.09Z" opacity=".2" />
      <path
        fill="#000"
        d="M10.44 32.32a2 2 0 0 1-.44-1.41v.15a1.94 1.94 0 0 0 .47 1.4l.07-.07-.1-.07ZM32 21.3l-5 2.83.09.09L32 21.44A1.75 1.75 0 0 0 33.06 20 1.86 1.86 0 0 1 32 21.3Z"
        opacity=".12"
      />
      <path
        fill="#fff"
        d="M12.51 7.62 32 18.7a1.86 1.86 0 0 1 1.06 1.3A1.75 1.75 0 0 0 32 18.56L12.51 7.48c-1.39-.8-2.54-.14-2.54 1.46v.15c.03-1.6 1.15-2.26 2.54-1.47Z"
        opacity=".25"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth=".2"
        d="M42.11 14.27h-.73v-2H42a2.841 2.841 0 0 0 .73-2.27V7h4v5.26h.67v2h-.74V13h-4.55v1.27ZM43.5 10a4 4 0 0 1-.57 2.29h3V7.74H43.5V10Zm9.91 2.22a3.1 3.1 0 0 1-4.39 0 3.21 3.21 0 0 1 0-4.44 3.1 3.1 0 0 1 4.39 0A3.08 3.08 0 0 1 54.3 10a3.001 3.001 0 0 1-.89 2.22Zm-3.83-.5a2.22 2.22 0 0 0 1.63.68 2.19 2.19 0 0 0 1.63-.68 2.54 2.54 0 0 0 0-3.44 2.19 2.19 0 0 0-1.63-.68 2.22 2.22 0 0 0-1.63.68 2.57 2.57 0 0 0 0 3.44Zm8.6 1.41a2.999 2.999 0 0 1-2.18-.89 3.24 3.24 0 0 1 0-4.48 3 3 0 0 1 2.22-.89 2.75 2.75 0 0 1 2.2 1l-.53.52a2 2 0 0 0-1.67-.77 2.25 2.25 0 0 0-1.66.67 2.57 2.57 0 0 0 0 3.46 2.25 2.25 0 0 0 1.66.67 2.361 2.361 0 0 0 1.78-.89l.54.53a3 3 0 0 1-1 .78 3.09 3.09 0 0 1-1.36.29Zm5.5-.13h-.77V7.74h-1.68V7h4.12v.74h-1.67V13Zm6.97-6-2.27 5.06a1.45 1.45 0 0 1-2 .94l.2-.71a.86.86 0 0 0 .4.11.72.72 0 0 0 .42-.11.91.91 0 0 0 .27-.38l.22-.48-2-4.43h.91l1.48 3.44L69.74 7h.91Zm.87 6V7h4.34v6h-.77V7.74h-2.8V13h-.77Zm5.71 0V7H78v2.57h3V7h.78v6H81v-2.71h-3V13h-.77Zm10.83-.78a3.108 3.108 0 0 1-4.4 0 3.21 3.21 0 0 1 0-4.44 3.1 3.1 0 0 1 4.39 0 3.23 3.23 0 0 1 0 4.44h.01Zm-3.83-.5a2.19 2.19 0 0 0 1.63.68 2.22 2.22 0 0 0 1.63-.68 2.57 2.57 0 0 0 0-3.44 2.22 2.22 0 0 0-1.63-.68 2.19 2.19 0 0 0-1.63.68 2.54 2.54 0 0 0 0 3.44ZM92.09 13V7h2.17a1.8 1.8 0 0 1 1.22.46A1.51 1.51 0 0 1 96 8.63a1.38 1.38 0 0 1-.22.76 1.41 1.41 0 0 1-.59.49c.289.099.543.28.73.52a1.4 1.4 0 0 1 .28.85 1.57 1.57 0 0 1-.54 1.22 1.83 1.83 0 0 1-1.28.49l-2.29.04Zm.77-3.43h1.4a.87.87 0 0 0 .7-.29.9.9 0 0 0 .27-.63A.93.93 0 0 0 95 8a.85.85 0 0 0-.67-.28h-1.44l-.03 1.85Zm0 2.69h1.56a.92.92 0 0 0 .72-.31 1 1 0 0 0 .27-.68 1 1 0 0 0-.28-.67.999.999 0 0 0-.75-.31h-1.52v1.97Z"
      />
      <defs>
        <linearGradient id="google-play-a" x1="21.8" x2="5.02" y1="8.71" y2="25.49" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00A0FF" />
          <stop offset=".01" stopColor="#00A1FF" />
          <stop offset=".26" stopColor="#00BEFF" />
          <stop offset=".51" stopColor="#00D2FF" />
          <stop offset=".76" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient id="google-play-b" x1="33.83" x2="9.64" y1="20" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE000" />
          <stop offset=".41" stopColor="#FFBD00" />
          <stop offset=".78" stopColor="orange" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient id="google-play-c" x1="24.83" x2="2.07" y1="22.3" y2="45.05" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient id="google-play-d" x1="7.3" x2="17.46" y1=".18" y2="10.34" gradientUnits="userSpaceOnUse">
          <stop stopColor="#32A071" />
          <stop offset=".07" stopColor="#2DA771" />
          <stop offset=".48" stopColor="#15CF74" />
          <stop offset=".8" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GooglePlay
