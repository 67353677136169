import styled from 'styled-components'
import { Text } from '../../../../../components/ui/Typography'

export const Container = styled.div`
  text-align: center;
`

export const SupportText = styled(Text)`
  color: rgba(24, 27, 33, 0.65);
`

export const SupportLink = styled(Text)`
  color: #ff312c;
  text-decoration: none;
`
