import styled, { css } from 'styled-components'

import { SpaceProps, SpaceDirection, SpaceAlign, SpaceJustify, SpaceSize } from '../types/Space.types'

const getDirectionStyles = (direction?: SpaceDirection) => {
  switch (direction) {
    case 'horizontal':
      return css`
        flex-direction: row;
      `
    case 'vertical':
      return css`
        flex-direction: column;
      `
    case 'row-reverse':
      return css`
        flex-direction: row-reverse;
      `
    case 'column-reverse':
      return css`
        flex-direction: column-reverse;
      `
  }
}
const getAlignStyles = (align?: SpaceAlign) => {
  switch (align) {
    case 'start':
      return css`
        align-items: flex-start;
      `
    case 'end':
      return css`
        align-items: flex-end;
      `
    case 'center':
      return css`
        align-items: center;
      `
    case 'baseline':
      return css`
        align-items: baseline;
      `
  }
}
const getJustifyStyles = (justify?: SpaceJustify) => {
  switch (justify) {
    case 'start':
      return css`
        justify-content: flex-start;
      `
    case 'end':
      return css`
        justify-content: flex-end;
      `
    case 'center':
      return css`
        justify-content: center;
      `
    case 'space-between':
      return css`
        justify-content: space-between;
      `
    case 'space-around':
      return css`
        justify-content: space-around;
      `
    case 'stretch':
      return css`
        justify-content: stretch;
      `
  }
}
const getWrapStyles = (wrap?: boolean) =>
  wrap &&
  css`
    flex-wrap: wrap;
  `
const getInlineStyles = (inline?: boolean) =>
  inline &&
  css`
    display: inline-flex;
    width: auto;
  `
const getSizeStyles = (size?: SpaceSize, direction?: SpaceDirection) => {
  switch (direction) {
    case 'horizontal':
      return css`
        // polyfill
        // & > * + * {
        //   margin-left: ${size}px;
        // }

        gap: ${size}px;
      `
    case 'vertical':
      return css`
        // polyfill
        // & > * + * {
        //   margin-top: ${size}px;
        // }

        gap: ${size}px;
      `
  }
}

export const Container = styled.div<SpaceProps>`
  display: flex;
  flex-wrap: nowrap;
  align-items: normal;
  width: 100%;

  ${({ direction }) => getDirectionStyles(direction)}
  ${({ align }) => getAlignStyles(align)}
  ${({ justify }) => getJustifyStyles(justify)}
  ${({ wrap }) => getWrapStyles(wrap)}
  ${({ inline }) => getInlineStyles(inline)}
  ${({ size, direction }) => getSizeStyles(size, direction)}
`
