import styles, { css } from 'styled-components'

import { IconProps, IconSize } from '../types/Icon.types'

const getSizeStyles = (size?: IconSize) => {
  switch (size) {
    case 16:
      return css`
        width: 16px;
        height: 16px;
      `
    case 20:
      return css`
        width: 20px;
        height: 20px;
      `
    case 24:
      return css`
        width: 24px;
        height: 24px;
      `
  }
}

const aseContainerStyles = css`
  box-sizing: content-box;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.secondary};
`

const getContainerStyles = (asContainer?: boolean, size?: IconSize) => {
  if (asContainer) {
    switch (size) {
      case 16:
      case 20:
        return css`
          padding: 10px;
          ${aseContainerStyles};

          color: ${({ theme }) => theme.colors.text};
        `
      case 24:
        return css`
          padding: 12px;
          ${aseContainerStyles};
          color: ${({ theme }) => theme.colors.text};
        `
    }
  }

  return ''
}

export const Container = styles.span<IconProps & { asContainer?: boolean }>`
  display: inline-flex;
  flex-shrink: 0;
  color: currentColor;

  & > svg {
    width: 100%;
  }

  ${({ size }) => getSizeStyles(size)}
  ${({ asContainer, size }) => getContainerStyles(asContainer, size)}
`
