import styled from 'styled-components'
import { Heading as BaseHeading, Text } from '../../../../../components/ui/Typography'
import { Tooltip } from '../../../../../components/ui/Tooltip'
import { Arrow } from '../../../../../components/ui/Tooltip/styles/Tooltip.sc'
import { FormControl as FieldFormControl } from './Field.sc'
import { Icon } from '../../../../../components/ui/Icon'

export const Container = styled.div`
  padding: 30px;
  border-radius: 20px;
  background: #f9fcfd;

  @media screen and (max-width: 411px) {
    padding: 30px 20px;
  }
`

export const Heading = styled(BaseHeading)`
  margin-bottom: 8px;
`

export const AddressHeading = styled(Heading)`
  margin-top: 32px;
`

export const InputGroup = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`

export const SubHeading = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  margin-bottom: 16px;
`

export const BlueIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.link};
`

export const Text80 = styled(Text)`
  opacity: 0.8;
`

export const TooltipTriggerText = styled(Text)`
  opacity: 0.65;
  text-decoration: underline;
  cursor: help;
  width: 249px;
`

export const KeyCodeFieldWrapper = styled.div`
  ${FieldFormControl} {
    margin-bottom: 8px;
  }
`

export const StyledTooltip = styled(Tooltip)`
  ${Arrow} {
    transform: translateX(13px) !important;
  }
`

export const StyledTooltipContent = styled.div`
  padding: 8px 0;
`
