import { useCallback, useState, useEffect } from 'react'

import { Heading } from '../../../components/ui/Typography'
import { FinalBlockOptions } from '../components/form/types/FinalBlock.types'
import { STEPS } from '../constants/Home.constants'
import { Banner, FinalBlock, KeyCopyForm, KeyForm, Support } from '../components/form'
import { Container, Content, ContentBlock, SubHeading } from '../styles/Home.sc'
import { IFormState } from '../components/form/types/form.types'
import { runtimeConfig } from '../../../config'
import { openPageEvent } from '../../../events/keys'
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha'

const { RECAPTCHA_CLIENT_SECRET } = runtimeConfig

const HomePage = () => {
  const [state, setState] = useState({})
  const [step, setStep] = useState<string>(STEPS.KEY_FORM)
  const [finalState, setFinalState] = useState<FinalBlockOptions>()
  const [token, setToken] = useState('')
  const resetToken = useCallback(() => {
    setToken('')
  }, [setToken])
  const [captchaVisibility, setCaptchaVisible] = useState(false)
  const showCaptcha = useCallback(() => setCaptchaVisible(true), [setCaptchaVisible])
  const hideCaptcha = useCallback(() => setCaptchaVisible(false), [setCaptchaVisible])

  useEffect(() => {
    openPageEvent()
  }, [])

  const handleChangeStep = useCallback((step: STEPS, patch?: Partial<IFormState>) => {
    if (patch) {
      setState((state) => ({
        ...state,
        ...patch,
      }))
    }

    setStep(step)
  }, [])

  const getReCaptcha = useCallback(() => {
    return {
      token,
    }
  }, [token])

  const handleKeyFormFinish = useCallback((patch) => {
    handleChangeStep(STEPS.KEY_COPY_FORM, patch)
  }, [])

  const handleKeyCopyFormFinish = useCallback((patch, finalState: FinalBlockOptions) => {
    setFinalState(finalState)
    handleChangeStep(STEPS.FINAL, patch)
  }, [])

  let content

  switch (step) {
    case STEPS.KEY_FORM: {
      content = (
        <>
          <ContentBlock>
            <Heading level={3}>
              Активация нового
              <br />
              ключа для домофона
            </Heading>
            <SubHeading size={20}>
              Активируйте ключ домофона от дома,
              <br />в котором вы живете
            </SubHeading>
          </ContentBlock>
          <ContentBlock indent={32}>
            <Banner />
          </ContentBlock>
          <ContentBlock indent={20}>
            <KeyForm showCaptcha={showCaptcha} token={token} state={state} onFinish={handleKeyFormFinish} />
          </ContentBlock>
          <ContentBlock indent={24}>
            <Support />
          </ContentBlock>
        </>
      )

      break
    }

    case STEPS.KEY_COPY_FORM: {
      content = (
        <>
          <ContentBlock>
            <Heading level={3}>Создание копии ключа</Heading>
          </ContentBlock>
          <ContentBlock indent={20}>
            <KeyCopyForm showCaptcha={showCaptcha} token={token} state={state} onFinish={handleKeyCopyFormFinish} />
          </ContentBlock>
          <ContentBlock indent={24}>
            <Support />
          </ContentBlock>
        </>
      )

      break
    }

    case STEPS.FINAL: {
      content = <FinalBlock {...finalState} onChangeStep={handleChangeStep} />

      break
    }
  }

  return (
    <Container>
      <Content>{content}</Content>
      <InvisibleSmartCaptcha
        sitekey={RECAPTCHA_CLIENT_SECRET}
        onSuccess={setToken}
        onChallengeHidden={hideCaptcha}
        onTokenExpired={resetToken}
        visible={captchaVisibility}
      />
    </Container>
  )
}

export default HomePage
