import styled, { css, keyframes } from 'styled-components'

import { SpinnerProps, SpinnerSize, SpinnerVariant } from '../types/Spinner.types'

const loading = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`

const getSizeStyles = (size?: SpinnerSize) => {
  switch (size) {
    case 16:
      return css`
        width: 16px;
        height: 16px;
      `
    case 20:
      return css`
        width: 20px;
        height: 20px;
      `
    case 24:
      return css`
        width: 24px;
        height: 24px;
      `
    case 32:
      return css`
        width: 32px;
        height: 32px;
      `
    case 40:
      return css`
        width: 40px;
        height: 40px;
      `
  }
}

const getVariantStyles = (variant?: SpinnerVariant) => {
  switch (variant) {
    case 'primary':
      return css`
        color: ${({ theme }) => theme.colors.primary};
      `
    case 'secondary':
      return css`
        color: ${({ theme }) => theme.colors.text};
      `
  }
}

export const Container = styled.span<SpinnerProps>`
  display: inline-flex;

  ${({ size }) => getSizeStyles(size)}
  ${({ variant }) => getVariantStyles(variant)}
`

export const Image = styled.svg<SpinnerProps>`
  width: 100%;
  animation: ${loading} 1.5s linear infinite;
  transform-origin: center;
`

export const Circle = styled.path`
  fill: currentColor;
`
