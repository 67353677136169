import React from 'react'

// #518399
export const TelegramLogo = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 14" width={17} {...props}>
    <path
      d="M1.137 6.027C5.58 4.051 8.54 2.749 10.022 2.119 14.253.323 15.132.011 15.705 0c.127-.002.408.03.59.181a.66.66 0 0 1 .218.421 2.8 2.8 0 0 1 .025.613c-.23 2.46-1.221 8.428-1.726 11.183-.213 1.166-.634 1.557-1.041 1.595-.885.083-1.557-.597-2.414-1.17-1.34-.898-2.098-1.457-3.4-2.332-1.504-1.012-.529-1.569.329-2.478.224-.238 4.122-3.858 4.197-4.186.01-.041.019-.194-.07-.275-.09-.081-.221-.053-.316-.031-.134.03-2.276 1.476-6.426 4.336-.608.427-1.159.634-1.652.623-.544-.012-1.59-.314-2.369-.572C.696 7.592-.062 7.424.004 6.886c.034-.28.412-.566 1.133-.859Z"
      fill="currentColor"
    />
  </svg>
))
