import { VKLogo } from '../../../../components/icons/src/VKLogo'
import { TelegramLogo } from '../../../../components/icons/src/TelegramLogo'
import { ViberLogo } from '../../../../components/icons/src/ViberLogo'
import { OKLogo } from '../../../../components/icons/src/OKLogo'
import { Heading } from '../../../../components/ui/Typography'
import Download from './Download'
import {
  Copyrights,
  CopyrightsInner,
  FooterContainer,
  FooterGrid,
  FooterInner,
  FooterLeft,
  LinksContainer,
  Links,
  LinksItem,
  SocialLink,
  JustLink,
  PhoneContainer,
  Phone,
  SocialsContainer,
  SocialsHeader,
  SocialsList,
  SocialsListItem,
  SocialsListLink,
  FooterRight,
  VKSocialLinkIcon,
  CompanyName,
} from './styles/Footer.sc'

export const Footer = () => (
  <FooterContainer>
    <FooterInner>
      <FooterGrid>
        <FooterLeft>
          <Heading level={6}>Как с нами связаться</Heading>
          <LinksContainer>
            <Links>
              <LinksItem>
                <SocialLink href="https://vk.me/domru">
                  <VKLogo />
                  Написать ВKонтакте
                </SocialLink>
              </LinksItem>
              <LinksItem>
                <SocialLink href="https://t.me/dom_ru_bot">
                  <TelegramLogo />
                  Написать в Telegram
                </SocialLink>
              </LinksItem>
              <LinksItem>
                <SocialLink href="https://chats.viber.com/dom.ru/ru">
                  <ViberLogo />
                  Написать в Viber
                </SocialLink>
              </LinksItem>
            </Links>
            <Links>
              <LinksItem>
                <JustLink href="mailto:help@domru.ru">Написать на почту</JustLink>
              </LinksItem>
              <LinksItem>
                <JustLink href="https://dom.ru/service/feedback">Создать обращение</JustLink>
              </LinksItem>
            </Links>
          </LinksContainer>
          <PhoneContainer>
            <Phone href="tel:+74956627999">+7 (495) 662-7999</Phone>
          </PhoneContainer>
          <SocialsContainer>
            <SocialsHeader>Мы в соц. сетях</SocialsHeader>
            <SocialsList>
              <SocialsListItem>
                <SocialsListLink href="https://vk.com/domru" target="_blank" rel="noopener noreferrer nofollow" aria-label="social link">
                  <VKSocialLinkIcon as={VKLogo} />
                </SocialsListLink>
              </SocialsListItem>
              <SocialsListItem>
                <SocialsListLink href="https://ok.ru/domru" target="_blank" rel="noopener noreferrer" aria-label="social link">
                  <OKLogo />
                </SocialsListLink>
              </SocialsListItem>
            </SocialsList>
          </SocialsContainer>
        </FooterLeft>
        <FooterRight>
          <Download />
        </FooterRight>
      </FooterGrid>
      <Copyrights>
        <CopyrightsInner>
          <p>
            Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookies
            <br /> и других пользовательских данных, в соответствии с&nbsp;
            <a href="https://dom.ru/policy/app">Политикой конфиденциальности</a>.
          </p>
          <CompanyName>© АО «ЭР-Телеком Холдинг» 2011-2022</CompanyName>
        </CopyrightsInner>
      </Copyrights>
    </FooterInner>
  </FooterContainer>
)
