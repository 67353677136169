import styled, { css } from 'styled-components'

import { getTextAlpha02Color, getSecondaryTint07Color, getTextAlpha065Color } from '../../../../styles/utils'

import { MenuItemProps } from '../types/MenuItem.types'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Item = styled.div<MenuItemProps>`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.3s ${({ theme }) => theme.easing.inOut};
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.gray};
    display: none;
  }

  &:hover {
    background-color: ${getSecondaryTint07Color};
  }

  ${({ active }) =>
    active &&
    css`
      &:after {
        display: block;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: ${getTextAlpha02Color};
    `}
`

export const Description = styled.div<{ disabled?: boolean }>`
  color: ${getTextAlpha065Color};
  width: 100%;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${getTextAlpha02Color};
    `}
`

export const Text = styled.div`
  display: flex;
  //align-items: center;
  //justify-content: center;
  user-select: none;
  width: 100%;
`

export const Label = styled.div`
  color: ${getTextAlpha065Color};
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`

export const Control = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-self: flex-start;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`
