import * as React from 'react'

import { Danger, TickCircle } from '../../../icons'

import { NotificationProps } from '../types/Notifications.types'
import { Notification as NotificationStyled, Icon } from '../styles/Notifications.sc'

/**
 * @description Notification component.
 *
 * @component
 * @example
 * ```jsx
 * <Notification />
 * ```
 */
const Notification: React.FC<NotificationProps & { onClose: (id: NotificationProps['id']) => void }> = React.memo((props) => {
  const { type, message, ...otherProps } = props

  const icon = React.useMemo(() => {
    switch (type) {
      case 'success':
        return <Icon type={type} icon={<TickCircle />} />
      case 'error':
        return <Icon type={type} icon={<Danger />} />
    }
  }, [type])

  return (
    <NotificationStyled {...otherProps}>
      {icon}
      {message}
    </NotificationStyled>
  )
})

Notification.displayName = 'Notification'

export default Notification
