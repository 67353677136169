import http from '../utils/http'

export const fetchRegions = async (): Promise<ILocationResponse> => {
  const { data } = await http.get<ILocationResponse>('/locations/regions')

  return data
}

export const fetchStreets = async (regionId: number): Promise<ILocationResponse> => {
  const { data } = await http.get<ILocationResponse>(`/locations/regions/${regionId}/streets`)

  return data
}

export const fetchHouses = async (regionId: number, streetId: number): Promise<ILocationResponse> => {
  const { data } = await http.get<ILocationResponse>(`/locations/regions/${regionId}/streets/${streetId}/houses`)

  return data
}
