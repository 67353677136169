import * as React from 'react'
import { TooltipCard } from '../../../../../components/icons/src/TooltipCard'
import { Text } from '../../../../../components/ui/Typography'
import { StyledTooltipContent } from '../styles/KeyForm.sc'

export const TooltipContent = () => {
  return (
    <StyledTooltipContent>
      <TooltipCard />
      <Text size={12}>
        Найдите на ключе 8-значный
        <br />
        номер из латинских букв и цифр
      </Text>
    </StyledTooltipContent>
  )
}
