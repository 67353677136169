import styled, { css, keyframes } from 'styled-components'

import {
  getHoverColor,
  getActiveColor,
  getTextColor,
  getWhiteShade022Color,
  getTextAlpha02Color,
  getTextAlpha065Color,
  getTextAlpha01Color,
} from '../../../../styles/utils'

import { ButtonSize, ButtonVariant, ButtonProps } from '../types/Button.types'

const gradientAnimation = keyframes`
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: -200% 50%;
  }
`

const textAnimation = keyframes`
  0% {
    opacity: .65;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: .65;
  }
`

const getBlockStyles = (block?: boolean) => {
  if (block) {
    return css`
      display: flex;
      width: 100%;

      &:active {
        transform: scale(0.98);
      }
    `
  } else {
    return css`
      &:active {
        transform: scale(0.95);
      }
    `
  }
}
const getLoadingStyles = (variant?: ButtonVariant, loading?: boolean) => {
  if (loading) {
    switch (variant) {
      case 'text': {
        return css`
          background: transparent;
          animation: ${textAnimation} 3s ease infinite forwards;
        `
      }

      default: {
        return css`
          pointer-events: none;
          color: ${getTextAlpha01Color};
          background: #d8dadc linear-gradient(-90deg, #d8dadc 0%, #f2f3f3 10%, #d8dadc 20%);
          background-size: 200% 200%;
          animation: ${gradientAnimation} 3s ease infinite forwards;
        `
      }
    }
  }
}

const getSizeStyles = (size?: ButtonSize) => {
  const stylesBlock = css`
    height: ${size}px;
    border-radius: ${size && size / 2}px;
  `

  switch (size) {
    case 36:
      return css`
        ${stylesBlock};
        padding: 0 16px;
        font-size: 14px;
        line-height: 20px;
      `
    case 40:
      return css`
        ${stylesBlock};
        padding: 0 24px;
        font-size: 16px;
        line-height: 24px;
      `
    case 48:
      return css`
        ${stylesBlock};
        padding: 0 24px;
        font-size: 16px;
        line-height: 24px;
      `
  }
}

export const generateButtonVariant = (color: string) => {
  return css`
    color: ${({ theme }) => getTextColor(color, theme.colors.text, theme.colors.white)};
    background: ${color};

    &:hover {
      background: ${getHoverColor(color)};
    }

    &:active {
      background: ${getActiveColor(color)};
    }
  `
}

const getVariantStyles = (variant?: ButtonVariant, loading?: boolean) => {
  if (!loading) {
    switch (variant) {
      case 'action':
        return css`
          ${({ theme }) => generateButtonVariant(theme.colors.primary)};
        `
      case 'primary':
        return css`
          ${({ theme }) => generateButtonVariant(theme.colors.gray)};
        `
      case 'secondary':
        return css`
          ${({ theme }) => generateButtonVariant(theme.colors.secondary)};
        `
      case 'auxiliary':
        return css`
          ${({ theme }) => generateButtonVariant(theme.colors.white)};
        `
      case 'text':
        return css`
          border: none;
          color: ${({ theme }) => theme.colors.text};
          background: transparent;
          padding: 0;

          &:hover {
            color: ${getTextAlpha065Color};
          }

          &:active {
            color: ${({ theme }) => theme.colors.text};
          }

          &[disabled] {
            background: none;
            color: ${getTextAlpha02Color};
          }
        `
    }
  }
}

export const Container = styled('button').withConfig<{ loading?: boolean }>({
  shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'loading' && defaultValidatorFn(prop),
})<ButtonProps>`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-weight: 500;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  gap: 8px;
  border-radius: 12px;
  border: none;
  letter-spacing: 0.01em;
  will-change: color, background-color;
  transition: all 0.2s ${({ theme }) => theme.easing.linear};

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    color: ${getTextAlpha02Color};
    background: ${getWhiteShade022Color};
  }

  ${({ block }) => getBlockStyles(block)}
  ${({ size }) => getSizeStyles(size)}
  ${({ variant, loading }) => getVariantStyles(variant, loading)}
  ${({ variant, loading }) => getLoadingStyles(variant, loading)}
`

export const Text = styled.span`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 4px;
`
